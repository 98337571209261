import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Tab, Tabs } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import After10th from "./After10th";
import After12th from "./After12th";
import AfterGraduation from "./AfterGraduation";
import AfterPostgraduate from "./AfterPostgraduate";
import EnquiryNow from "../common/EnquiryNow3";

const ProgrammesByQualification = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Programmes By Qualification</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Programmes By Qualification</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Programmes By Qualification</h2>
            <p>
              ISBM University offers a variety of focused programs designed to
              cater to specific fields of study and professional development.
              These programs often include specialized courses in areas such as
              business management, information technology, pharmacy and more.
              The curriculum is tailored to meet industry demands, providing
              students with practical knowledge and skills through hands-on
              experiences, case studies, and projects. Additionally, ISBM
              emphasizes research and innovation, encouraging students to engage
              in projects that contribute to their fields. With experienced
              faculty and state-of-the-art facilities, the University aims to
              prepare students for successful careers in their chosen
              disciplines. With a focus on fostering creativity and critical
              thinking, ISBM University prepares students to thrive in their
              chosen careers and contribute meaningfully to society.
            </p>
            <Tabs
              defaultActiveKey="home"
              transition={false}
              className="programmesByQualification"
              id="noanim-tab-example"
            >
              <Tab eventKey="home" title="After 10th Programmes">
                <Row className="tenth">
                  <ul className="inner">
                    <After10th />
                  </ul>
                </Row>
              </Tab>
              <Tab eventKey="profile" title="After 12th Programmes">
                <Row className="tenth">
                  <ul className="inner">
                    <After12th />
                  </ul>
                </Row>
              </Tab>
              <Tab eventKey="Undergraduate" title="After Graduation Programmes">
                <Row className="tenth">
                  <ul className="inner">
                    <AfterGraduation />
                  </ul>
                </Row>
              </Tab>
              <Tab
                eventKey="Postgraduate"
                title="After Post-Graduation Programmes"
              >
                <Row className="tenth">
                  <ul className="inner">
                    <AfterPostgraduate />
                  </ul>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgrammesByQualification;
