import React, { useEffect, useState } from "react";
import {  Container, Row, Col,Breadcrumb, Accordion,} from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";


const Statues = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <>
        <Topnav />
        <Menubar />
        <div className="statues2innerbackimage innerbackimage">
          <div className="text_banners_abcds">
            <h1>Statutes and Ordinances</h1>
            <div  onClick={() => setModalShow(true)}>
              <span>
                <a className="btn btn-primary" href="#" role="button">
                  Apply Now
                </a>
              </span>
            </div>
            <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Statutes and Ordinances</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Sidebaar />
            <Col lg={9}>
            <Accordion  defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mt-3">
                <Accordion.Header > Statutes and Ordinances </Accordion.Header>
                <Accordion.Body>
                  <ul className="text-purple font-weight-bold">
                    <li>
                      <a
                    //    href={pdf1}
                        target={"/"}
                       className="text-darkpurple text-decoration-none"
                      >
                        Statutes and Ordinances
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-3">
                <Accordion.Header> Ordinances</Accordion.Header>
                <Accordion.Body>
                  <ul className="text-purple font-weight-bold">
                    <li>
                      <a
                        //href={ordinances1}
                        className="text-darkpurple text-decoration-none"
                      >
                        Ordinances - 1
                      </a>
                    </li>
                    <li>
                      <a
                       // href={ordinances2}
                       className="text-darkpurple text-decoration-none"
                      >
                        Ordinances - 2
                      </a>
                    </li>
                    <li>
                      <a
                        //href={ordinances3}
                        className="text-darkpurple text-decoration-none"
                      >
                        Ordinances - 3
                      </a>
                    </li>
                    <li>
                      <a
                        // href={ordinances4}
                        className="text-darkpurple text-decoration-none"
                      >
                        Ordinances - 4
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mt-3">
                <Accordion.Header>Statutes</Accordion.Header>
                <Accordion.Body>
                  <ul className="text-purple font-weight-bold">
                    <li>
                      <a
                    //    href={statutes1}
                    className="text-darkpurple text-decoration-none"
                      >
                        Statutes
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }


export default Statues;
