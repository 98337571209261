import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import abc1 from "../../Images/Hon'ble Persons/Dr. Umesh Kumar Mishra.webp";
import abc2 from "../../Images/Hon'ble Persons/Chief Minister Shri Vishnu Dev Sai.webp";

import EnquiryNow from "../common/EnquiryNow3";

const Message = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="msginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Messages of the Dignitaries</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Messages of the Dignitaries</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <div className="row justify-content-center mt-5 pt-5">
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc1}
                        alt=" Chhattisgarh Niji Vishwavidyalaya Niyamak Aayog Dr. Umesh Kumar Mishra"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Dr. Umesh Kumar Mishra
                      </h5>
                      <div className="mb-3 text-center">
                        Chhattisgarh Niji Vishwavidyalaya Niyamak Aayog
                      </div>
                      <div>
                        Chhattisgarh Private Regulatory Commission, a statutory
                        body of the Govt. of Chhattisgarh. It is responsible for
                        providing guidance and supervision to the private
                        Universities Established in the State of Chhattisgarh.
                        The commission is dedicated to monitoring and
                        supervising the academic standard of private
                        Universities. The commission functions under the general
                        control of the Visitor i.e. the Hon’ble Governor of
                        Chhattisgarh. It consists of Chairman, Member Academic,
                        and Member Administration. The Secretary looks after the
                        functional aspect of the commission. The commission
                        provides a regulatory mechanism in order to ensure
                        appropriate standards in teaching, examination,
                        research, protecting the interest of the student, and
                        providing reasonable service conditions to the employees
                        working in private Universities.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                  <div className="card-body p-4">
                    <div className="member-profile position-absolute w-100 text-center">
                      <img
                        className="rounded-circle mx-auto d-inline-block shadow-sm"
                        src={abc2}
                        alt="Shri Vishnu Dev Sai"
                      />
                    </div>
                    <div className="card-text pt-3 mt-5">
                      <h5 className="member-name mb-0 text-center text-primary font-weight-bold">
                        Shri Vishnu Dev Sai
                      </h5>
                      <div className="mb-3 text-center">
                        Hon’ble Chief Minister
                      </div>
                      <p>
                        Hon’ble Shri Vishnu Deo Sai Ji is an Indian politician
                        currently serving as the Chief Minister of Chhattisgarh.
                        He is the first senior tribal leader in Central India.
                        Shri Vishnu Deo Sai Ji is the member of Bharatiya Janata
                        Party (BJP) and Rashtriya Swayamsevak Sangh (RSS).He
                        currently represents Kunkuri in Chhattisgarh Legislative
                        Assembly. Shri Vishnu Deo Sai Ji is known for his
                        decisive approach in addressing public welfare issues.
                        His dedication extends beyond Chhattisgarh, as he has
                        actively engaged with tribal concerns in states
                        including Madhya Pradesh, Odisha, Telangana, and
                        Maharashtra.
                      </p>
                      <p>
                        Beyond politics, Shri Vishnu Deo Sai Ji has actively
                        engaged in social work, particularly focused on tribal
                        education and the development of essential
                        infrastructure in tribal regions. He has been a
                        consistent advocate for improving the living standards
                        of backward tribes, reflecting a deep-rooted commitment
                        to social welfare.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Message;
