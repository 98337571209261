import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import perleg from "../../Images/logo/per-lg.webp";
import alpha from "../../Images/logo/alpha.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";

const AcademicPartners = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>
          ISBM University | Academic Partners Program | Collaboration
        </title>
        <meta name="Explore collaborative academic ventures at ISBM University. Discover our esteemed partnerships fostering educational excellence and global learning opportunities." />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University | Academic Partners Program | Collaboration"
        />
        <meta
          property="og:description"
          content="Explore collaborative academic ventures at ISBM University. Discover our esteemed partnerships fostering educational excellence and global learning opportunities."
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University | Academic Partners Program | Collaboration"
        />
        <meta
          name="twitter:description"
          content="Explore collaborative academic ventures at ISBM University. Discover our esteemed partnerships fostering educational excellence and global learning opportunities."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Academic Partnerships, Collaborative Alliances, ISBM University Alliances"
        />
      </MetaTags>

      <Topnav />
      <Menubar />
      <div className="partnersinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Academic Partners</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Academic Partners</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar/>
          <Col lg={9}>
          <h2 className="linkabout123">Academic Partners</h2>
          <Accordion  defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Arean Foundation</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4}>
                      <img src={alpha} alt="Arean Foundation" />
                    </Col>
                    <Col lg={8}>
                      <p>
                        Arean Foundation is a government registered NGO,
                        determined to funding, footing and sustaining actual
                        grassroots initiatives focused at providing learning and
                        wellbeing to disadvantaged children. In this process,
                        the foundation has achieved position as the first ever
                        grant maker and changing the face of thousands of lives.
                        Alpha Foundation aims at Welfare Reforms for
                        underprivileged children, Social welfare and Health
                        Welfare. It is an Indian NGO for Social Services, Indian
                        Child Welfare Services, Child Education and Self
                        Employment.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-3">
                <Accordion.Header>Pearson Education</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4}>
                      <img src={perleg} alt="Pearson Education"/>
                    </Col>
                    <Col lg={8}>
                      <p>
                        Educating 100 million people worldwide, Pearson
                        Education is the world's biggest education company.
                        Pearson Education's solutions cover a wide spectrum of
                        subjects including business, technology, sciences, law
                        and the humanities. They include books and resources
                        that help students learn, teachers teach, and
                        professionals evolve throughout their careers. The
                        carefully designed learning tools help people around the
                        world to expand their knowledge, develop their skills
                        and realize their potential. The company is committed to
                        provide quality content, assessment tools and
                        educational services in all available media, spanning
                        the learning curve from birth through university and
                        beyond.
                      </p>
                      <p>
                        Pearson Education India specializes in the publication
                        of academic and reference books in the fields of
                        computer science, engineering, business & management,
                        professional & trade, higher education and competitive
                        examination preparation books. Pearson Education India
                        is also India's foremost publisher in the school segment
                        (K-12), with book lists in English language teaching
                        (ELT), the humanities, sciences and mathematics, from
                        primary to senior secondary classes.
                      </p>
                      <p>
                        With elaborate editorial facilities in Delhi, Chennai
                        and Chandigarh, Pearson Education India covers the
                        entire subcontinent and has specific divisions with
                        experienced editorial teams catering to all levels and
                        fields of education. The teams create indigenous
                        publishing programmes to meet local market needs, and
                        undertake customized publishing for schools,
                        universities and other institutions. Pearson Education
                        India also works closely with authors and customers
                        through strong editorial development processes and
                        innovations in sales and marketing.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AcademicPartners;
