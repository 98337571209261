import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";

import EnquiryNow from "../common/EnquiryNow3";
 

const Faqs = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="faqinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Faqs</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Faqs</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Faqs</h2>
            {/* <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  FAQ for TC/CC & Migration Certificate
                </Accordion.Header>
                <Accordion.Body> */}
                  <Row>
                    <div className="col-lg-12">
                      <p>
                        <span>Q.1</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          I want Transfer Certificate how can I process.
                        </span>
                      </p>
                      <p>
                        <span>Ans.- </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>Yes,&nbsp;</span>
                      </p>
                      <ol type="I">
                        <li>
                        You can get Transfer Certificate by applying in the prescribed form. The form is available in the University website. 
                        </li>
                        <li>Attach self-attested copy of your mark sheet.</li>
                        <li> Prescribed fees</li>
                      </ol>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.2</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                        I want Character Certificate haw can I process.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - Yes,&nbsp;</span>
                      </p>
                      <ol type="I">
                      <li>
                        You can get Transfer Certificate by applying in the prescribed form. The form is available in the University website. 
                        </li>
                        <li>Attach self-attested copy of your mark sheet.</li>
                        <li> Prescribed fees</li>
                      </ol>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.3 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                        I want Migration Certificate, how can I process. &nbsp;
                        </span>
                      </p>
                      <p>
                        <span>Ans. - Yes,&nbsp;</span>
                      </p>
                      <ol type="I">
                        <li>You can get Migration Certificate by applying in the prescribed form. The form is available in the University website. </li>
                        <li>Attach self attested copy of your mark sheets.</li>
                        <li>Prescribed fees</li>
                      </ol>
                     <p>&nbsp;</p>

                      <p>
                        <span>Q.4 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I apply Transfer Certificate, Character
                          Certificate and Migration Certificate&nbsp; in any one
                          form or there is separate form for it.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                        Yes, for issue of transfer certificate, Character certificate and Migration Certificate a 
                        student can apply in a single form, the form is available in the University website.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.5</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                         What will be the fee payable for Transfer Certificate, Character Certificate and Migration Certificate.
                        </span>
                      </p>
                      <p>
                       <span>Ans. - </span>
                       <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                        Fee payable for Transfer Certificate and Character Certificate is Rs 1000/-, and  for Migration Certificate it is Rs 1000/-. 
                        Transfer Certificate, Character Certificate and Migration Certificate fee will be Rs 2000/- (in combination).
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.6 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          What will be the time line to get Transfer
                          Certificate, Character Certificate and Migration
                          Certificate (How much time it will take to get the
                          Transfer Certificate, Character Certificate and
                          Migration Certificate).
                        </span>
                      </p>
                      <p>
                        <span>Ans. -</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          One month from the date of application in normal
                          condition. It can differ as per situation.&nbsp;
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.7 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I apply for Transfer Certificate, Character
                          Certificate and Migration Certificate&nbsp; through
                          online?
                        </span>
                      </p>
                      <p>
                        <span>Ans. -</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          No, applicant can apply in person. Hard copy
                          application will only be accepted.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.8 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I send the Demand draft for Transfer Certificate,
                          Character Certificate and Migration Certificate.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Yes, fees can be paid in the shape of Demand draft.
                          Demand draft may be drawn in favour of ISBM University
                          payabla at&nbsp; Chhura,Gariyaband.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.9 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Is self attested documents are also required.
                        </span>
                      </p>
                      <p>
                        <span>Ans.</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Yes, Self attested mark sheets/documents must be
                          attached with the application.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.10 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          What is the process of verification of
                          Certificates/Mark sheets and Degree.
                        </span>
                      </p>
                      <p>
                        <span>Ans.-</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Apply in the prescribed form available in University
                          website, fill in the form, send it via registered post
                          in the address of the University. No other mode will
                          be entertained. Attach self attested mark
                          sheets/documents by paying prescribed fees.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.11</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I apply&nbsp; Transfer Certificate, Character
                          Certificate and Migration Certificate in another or in
                          my own format.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          No, the candidate has to apply in the prescribed
                          format available in the University website only.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.12 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          To whom I should contact for Transfer Certificate,
                          Character Certificate and Migration Certificate.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Postal query will only be attended. No other mode will
                          be entertained.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.13 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I send mail/SMS/WhatsApp or any other social media
                          platform to request for Transfer Certificate,
                          Character Certificate and Migration Certificate.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans.- No, the candidate has to apply for Transfer
                          Certificate, Character Certificate and Migration
                          Certificate in person or via Registered post.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.14</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can any other person will apply for Transfer
                          Certificate, Character Certificate and Migration
                          Certificate&nbsp;
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans. – Only candidate can apply for himself/ herself
                          in person.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.15</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Is it possible to collect Transfer Certificate,
                          Character Certificate and Migration Certificate in
                          person.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans.- Yes, applicant can collect the Transfer
                          Certificate, Character Certificate and Migration
                          Certificate in person after getting information from
                          the University.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.16</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          In person application is accepted or not for Transfer
                          Certificate, Character Certificate and Migration
                          Certificate.
                        </span>
                      </p>
                      <p>
                        <span>Ans. – Yes, It is accepted.</span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.17</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I have to visit the campus to collect Transfer
                          Certificate, Character Certificate and Migration
                          Certificate.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans.- The applicant can collect Transfer Certificate,
                          Character Certificate and Migration Certificate in
                          person or provide self addressed stamped envelope to
                          send it by registered post.
                        </span>
                      </p>
                      <div>&nbsp;</div>
                    </div>
                  </Row>
                {/* </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mt-4">
                <Accordion.Header>
                  
                  FAQ for verification of
                  Documents/Marksheets/Certificates/Degree
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <p>
                        <span>
                          I want to verify the documents, how can I process
                          them.
                        </span>
                      </p>

                      <p>
                        <span>Ans.- </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>Yes,&nbsp;</span>
                      </p>
                     
                      <ol type="I">
                        <li>Applicant (Student/Employer/Third party
                          verification company) can verify the academic
                          documents/Marksheets/Certificates/Degree by applying
                          in the prescribed form. The form is available in the
                          University website.</li>
                        <li>Attach a self-attested copy of your mark
                          sheets/Certificates/Degree.</li>
                        <li>Prescribed fees</li>
                      </ol>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.2</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          What will be the fee payable for verification of the
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans. – Fee payable for verification of
                          Documents/Marksheets/ Certificates /Degree is Rs
                          5000/-
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.3 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          What will be the timeline to get verification of
                          Documents/Marksheets/Certificates/Degree (How much
                          time it will take to verify the
                          Documents/Marksheets/Certificates/Degree).
                        </span>
                      </p>
                      <p>
                        <span>Ans. -</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          One month from the date of application in normal
                          condition. It can differ as per the situation.&nbsp;
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.4</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I apply for verification of
                          Documents/Marksheets/Certificates/Degree online?
                        </span>
                      </p>
                      <p>
                        <span>Ans. -</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          No, applicants can apply via Registered Post. Hard
                          copy applications will only be accepted.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.5 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I send the Demand draft for verification of
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Yes, fees can be paid in the shape of a Demand draft.
                          Demand draft may be drawn in favor of ISBM University
                          payable at&nbsp; Chhura, Gariyaband.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.6 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Is self-attested documents are also required.
                        </span>
                      </p>
                      <p>
                        <span>Ans.</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Yes, Self-attested
                          Documents/Marksheets/Certificates/Degree (as the case
                          may be) must be attached with the application.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.7 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          What is the process of verification of
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>Ans.-</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Apply in the prescribed form available on the
                          University website, fill in the form, send it via
                          registered post at the address of the University. No
                          other mode will be entertained. Attach self-attested
                          Documents/Marksheets/Certificates/Degree by paying
                          prescribed fees.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.8</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I apply for verification of
                          Documents/Marksheets/Certificates/Degree in another or
                          in my own format.
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          No, the candidate has to apply in the prescribed
                          format available on the University website only.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.9 </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          To whom I should contact for the verification of
                          Documents/Marksheets/Certificates/Degree.&nbsp;
                        </span>
                      </p>
                      <p>
                        <span>Ans. - </span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Postal query will only be attended. No other mode will
                          be entertained.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.10&nbsp;</span>
                        <span>
                          Can I send mail/SMS/What's App or any other social
                          media platform to request for the verification of
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                   
                      <p>
                        <span>
                          Ans.- No, the candidate has to apply for the
                          verification of
                          Documents/Marksheets/Certificates/Degree via
                          Registered post.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.11</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can any other person will apply for the verification
                          of Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans. – Yes, the Employer or the Third Party
                          verification company can apply.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.12</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Is it possible to collect the verification of
                          Documents/Marksheets/Certificates/Degree in person.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans.- No, the verified documents or the rejected
                          Documents/Marksheets/Certificates/Degree will be sent
                          by Registered post to the applicant.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.13 In-person</span>
                        <span>
                          &nbsp;application is accepted or not for the
                          verification of
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans. – No, It is accepted via Registered post.
                        </span>
                      </p>
                      <p>&nbsp;</p>

                      <p>
                        <span>Q.14</span>
                        <span>&nbsp;&nbsp; &nbsp;</span>
                        <span>
                          Can I have to visit the campus to collect the verified
                          Documents/Marksheets/Certificates/Degree.
                        </span>
                      </p>
                      <p>
                        <span>
                          Ans.- No, The verified
                          Documents/Marksheets/Certificates/Degree will be sent
                          by registered post to the applicant directly.
                        </span>
                      </p>
                      <div>&nbsp;</div>
                    </div>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mt-4">
                <Accordion.Header>
                  How to apply for verification of Documents/Certificates/ Mark
                  sheets/Degree
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={12}>
                      <p>
                        Students are requested to visit the website of the
                        university & download the form meant for application of
                        Transfer Certificate, Character Certificate & Migration
                        Certificate.
                      </p>
                      <p>
                        The form has to be filled in by the student & submit in
                        person or send via registered post with the following
                        attachments-
                      </p>
                      <p>
                        (i) Self-attested copy of the mark sheet (Final year/
                        Final Sem.)
                      </p>

                      <p>
                        (ii) Prescribed fees, in cash in the University cash
                        counter or Demand Draft in favor of ISBM University
                        payable at Chhura, Gariyaband.
                      </p>

                      <p>
                        Please note:- Except registered post another mode will
                        not be entertained.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mt-4">
                <Accordion.Header>
                  How to apply for verification of Documents/Certificates/ Mark
                  sheets/Degree
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={12}>
                      <p>
                        Applicant (Students /Employers / Third Party
                        Verification companies who wants to verify the academic
                        documents) are requested to visit the website of the
                        University & download the form meant for application of
                        verification of Certificates/Mark sheets/Degree.
                      </p>
                      <p>
                        The form has to be filled in and send via registered
                        post to the University with the following attachments-
                      </p>
                      <p>
                        (i) Self-attested copy of the Documents/ Mark
                        sheets/Certificates/Degree.
                      </p>

                      <p>
                        (ii) Prescribed fees, in cash in University cash counter
                        or through Demand Draft in favour of ISBM University
                        payable at Chhura, Gariyaband.
                      </p>

                      <p>
                        Please note:- Except registered post another mode will
                        not be entertained.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion> */}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Faqs;
