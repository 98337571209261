import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import big_vacany from "../../Images/logo/big_vacancyy.webp";
import EnquiryNow from "../common/EnquiryNow3";

const Campustraining = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="traininginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Campus Recruitment & Interview Training Apply Now</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>
            Campus Recruitment & Interview Training Apply Now
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              Campus Recruitment & Interview Training Apply Now
            </h2>
            <h5 className="text-purple fw-bold">Campus recruitment</h5>
            <p className="detail_text">
              Campus recruitment, also known as campus placement or college
              placement, is when businesses visit educational institutions like
              colleges and universities to recruit students for internships or
              full-time positions.
            </p>
            <p>
              A study by shows that nearly 70% of companies are hiring new
              college graduates this year, illustrating the significance of
              campus recruitment. This type of recruitment is mutually
              beneficial for both students and companies. Students can secure
              employment before graduation, often in their field of study, at
              the same time, companies can access a pool of young, talented
              individuals eager to start their careers.
            </p>
            <p>
              On-campus recruitment is vital for companies to tap into a
              vibrant, innovative pool of potential employees with the latest
              knowledge and trends in their respective fields. This recruitment
              method is not only cost-effective, allowing companies to engage
              with multiple promising candidates in a single setting, but it
              also fosters a strong connection between the academic and
              corporate worlds.
            </p>
            <p>
              Also, campus recruitment enhances diversity in the workplace, as
              campuses are melting pots of talent from various backgrounds,
              offering a wide variety of skills and perspectives. This diversity
              can lead to a more dynamic, innovative, and inclusive work
              environment.{" "}
            </p>
            <h5 className="text-purple fw-bold">
              Main benefits of campus recruitment{" "}
            </h5>
            <ul>
              <li>
                <strong className="text-blue">
                  1. Access to Fresh Talent:
                </strong>{" "}
                Campuses are a hub for young, skilled, and enthusiastic
                candidates. Employers can tap into this pool to find new talent
                with the latest academic knowledge and skills.
              </li>
              <li>
                <strong className="text-blue">
                  2. Reduce the Overall Cost of Recruitment:
                </strong>{" "}
                Conducting recruitment activities on college campuses is often
                more affordable than other recruiting methods, as it reduces the
                need for extensive advertising and various screening stages.
              </li>
              <li>
                <strong className="text-blue">
                  3. Build Your Brand’s Reputation:
                </strong>{" "}
                Engaging with students on campus helps companies build their
                brand as an employer of choice among the younger generation,
                which benefits long-term recruitment strategies and overall
                market presence.
              </li>
              <li>
                <strong className="text-blue">
                  4. Partner with Academic Leaders in Your Industry:
                </strong>{" "}
                Companies can establish and maintain long-term relationships
                with universities through campus recruitment. This can
                facilitate collaboration in areas like research and development.
              </li>
              <li>
                <strong className="text-blue">
                  5. Enhancing Diversity Hiring:
                </strong>{" "}
                Campus recruiting allows companies to reach a diverse talent
                pool reflecting many backgrounds, study areas, skills, and
                experiences, improving diversity and inclusion efforts.
              </li>
            </ul>
            <h5 className="text-purple fw-bold">INTERVIEW TRAINING</h5>
            <p>
              Interviewing is an essential aspect of the hiring process and it
              is vital to make a positive impression during a job interview.
              Having the desired skills and abilities for an interview can
              increase your chances of getting hired for the desirable job. If
              you appear for an interview, getting training before an interview
              might prove beneficial.
            </p>
            <p>
              Training and Placement Team organizes the Mock Interviews at
              department level and also from the competent institutions to train
              the graduates about the dynamics of Job interviews and the
              behavioural,soft and HR skills required during the Job interview.
            </p>
            <p>
              Types of Interview Training Provided by ISBM Training and
              Placement Team for the Graduates{" "}
            </p>
            <h6 className="bg-turtiory p-1 text-blue fw-bold">
              1. Effective communication Training
            </h6>
            <p>
              Having a formal conversation with interviewers during an interview
              requires excellent communication skills. Interviewers expect
              candidates to demonstrate their verbal communication skills. ISBM
              Training and Placement Team provides candidates with practical
              tips and techniques for enhancing their skill sets in
              communication skills.{" "}
            </p>
            <h6 className="bg-turtiory p-1 text-blue fw-bold">
              2. Behavioural interview Training
            </h6>
            <p>
              This Training primarily outlines building rapports with candidates
              to ensure they are comfortable sharing their personal information
              and skills during an interview.{" "}
            </p>
            <h6 className="bg-turtiory p-1 text-blue fw-bold">
              3. Planning and organising Interview Training{" "}
            </h6>
            <p>
              It helps candidates understand and recognise legal policies and
              issues related to hiring. It teaches how to become efficient and
              better organised to stop wasting valuable time. It help candidates
              create better professional goals and develop better plans to
              achieve those goals.
            </p>
            <h6 className="bg-turtiory p-1 text-blue fw-bold">
              4. Coding interview preparation courses{" "}
            </h6>
            <p>
              ISBM Training and Placement Team provides premium lectures,
              theory, practice tests and assessment tests of subjects like
              computer science, programming language, data structures,
              algorithms, reasoning and aptitude.{" "}
            </p>
            <h6 className="bg-turtiory p-1 text-blue fw-bold">
              5. Advanced interviewing techniques
            </h6>
            <p>
              the advanced interviewing technique Training focuses on handling
              competent and behavioural interviews. The Training makes students
              adept at managing and handling workplace conflicts, analysing
              situations where they previously failed and guiding the with the
              best interview techniques for behavioural and traditional
              questions. It allows candidates to share skills, abilities and
              knowledge required by recruiters for a job role.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Campustraining;
