import React from "react";

const CourseList = () => {
  return (
    <>
      <option>Select Course</option>
      <option value="Doctor of Philosophy (Ph.D.) in Management">
        Doctor of Philosophy (Ph.D.) in Management
      </option>
      <option value="Master of Business Administration (MBA)">
        Master of Business Administration (MBA)
      </option>
      <option value="Executive Master of Business Administration (EMBA)">
        Executive Master of Business Administration (EMBA)
      </option>
      <option value="Bachelor of Business Administration (BBA)">
        Bachelor of Business Administration (BBA)
      </option>
      <option value="Post Graduate Diploma in Business Administration (PGDBA)">
        Post Graduate Diploma in Business Administration (PGDBA)
      </option>
      <option value="Diploma in Business Administration (DBA)">
        Diploma in Business Administration (DBA)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Science">
        Doctor of Philosophy (Ph.D.) in Science
      </option>
      <option value="Master of Science (M.Sc.)">
        Master of Science (M.Sc.)
      </option>
      <option value="M.Sc. in Stem Cells and Tissue Culture Engineering (M.Sc. SCTCE)">
        M.Sc. in Stem Cells and Tissue Culture Engineering (M.Sc. SCTCE)
      </option>
      <option value="M.Sc. in Fire & Safety (M.Sc. F&S)">
        M.Sc. in Fire & Safety (M.Sc. F&S)
      </option>
      <option value="M.Sc. in Forensic Science & Criminology (M.Sc. FSC)">
        M.Sc. in Forensic Science & Criminology (M.Sc. FSC)
      </option>
      <option value="Bachelor of Science (Hons)">
        Bachelor of Science (Hons)
      </option>
      <option value="Bachelor of Science (B.Sc.)">
        Bachelor of Science (B.Sc.)
      </option>
      <option value="B.Sc. in Forensic Science & Criminology (B.Sc. FSC)">
        B.Sc. in Forensic Science & Criminology (B.Sc. FSC)
      </option>
      <option value="B.Sc. in Fire & Safety"> B.Sc. in Fire & Safety </option>
      <option value="Post Graduate Diploma in Science (PGDSC)">
        Post Graduate Diploma in Science (PGDSC)
      </option>
      <option value="Diploma in Nutrition (DIN)">
        Diploma in Nutrition (DIN)
      </option>
      <option value="Diploma in Science"> Diploma in Science </option>
      <option value="Diploma in Forensic Science & Criminology">
        Diploma in Forensic Science & Criminology
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Commerce">
        Doctor of Philosophy (Ph.D.) in Commerce
      </option>
      <option value="Master of Commerce (M.Com)">
        Master of Commerce (M.Com)
      </option>
      <option value="B.Com in Computer Application">
        B.Com in Computer Application
      </option>
      <option value="Bachelor of Commerce (Hons)">
        Bachelor of Commerce (Hons)
      </option>
      <option value="Bachelor of Commerce (B.Com)">
        Bachelor of Commerce (B.Com)
      </option>
      <option value="Post Graduate Diploma in Commerce (PGDCOM)">
        Post Graduate Diploma in Commerce (PGDCOM)
      </option>
      <option value="Diploma in Commerce"> Diploma in Commerce </option>
      <option value="Doctor of Philosophy (Ph.D.) in Arts and Humanities">
        Doctor of Philosophy (Ph.D.) in Arts and Humanities
      </option>
      <option value="Master of Arts (M.A)"> Master of Arts (M.A) </option>
      <option value="Master of Social Work (MSW)">
        Master of Social Work (MSW)
      </option>
      <option value="Bachelor of Arts (Hons)"> Bachelor of Arts (Hons) </option>
      <option value="Bachelor of Arts (B.A)"> Bachelor of Arts (B.A) </option>
      <option value="Bachelor of Social Work (BSW)">
        Bachelor of Social Work (BSW)
      </option>
      <option value="Post Graduate Diploma in Rural Development">
        Post Graduate Diploma in Rural Development
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Engineering and Technology">
        Doctor of Philosophy (Ph.D.) in Engineering and Technology
      </option>
      <option value="Master of Technology (M.Tech)">
        Master of Technology (M.Tech)
      </option>
      <option value="Bachelor of Technology (B.Tech)">
        Bachelor of Technology (B.Tech)
      </option>
      <option value="Bachelor of Technology (B.Tech) (Lateral Entry)">
        Bachelor of Technology (B.Tech) (Lateral Entry)
      </option>
      <option value="Diploma in Engineering"> Diploma in Engineering </option>
      <option value="Diploma in Engineering (Lateral Entry)">
        Diploma in Engineering (Lateral Entry)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Information Technology">
        Doctor of Philosophy (Ph.D.) in Information Technology
      </option>
      <option value="M.Sc. in Information Technology (M.Sc IT)">
        M.Sc. in Information Technology (M.Sc IT)
      </option>
      <option value="M.Sc. in Computer Science (M.Sc. CS)">
        M.Sc. in Computer Science (M.Sc. CS)
      </option>
      <option value="Master of Computer Applications (MCA)">
        Master of Computer Applications (MCA)
      </option>
      <option value="M.Sc. in Data Science"> M.Sc. in Data Science </option>
      <option value="B.Sc. (Hons) in Information Technology">
        B.Sc. (Hons) in Information Technology
      </option>
      <option value="B.Sc. (Hons) in Computer Science">
        B.Sc. (Hons) in Computer Science
      </option>
      <option value="Bachelor of Computer Applications (BCA)">
        Bachelor of Computer Applications (BCA)
      </option>
      <option value="B.Sc. in Data Science"> B.Sc. in Data Science </option>
      <option value="Post Graduate Diploma in Computer Application (PGDCA)">
        Post Graduate Diploma in Computer Application (PGDCA)
      </option>
      <option value="Post Graduate Diploma in Data Science">
        Post Graduate Diploma in Data Science
      </option>
      <option value="Post Graduate Diploma in AI & ML">
        Post Graduate Diploma in AI & ML
      </option>
      <option value="Diploma in Computer Application (DCA)">
        Diploma in Computer Application (DCA)
      </option>
      <option value="Diploma in AI & ML"> Diploma in AI & ML </option>
      <option value="B.Sc. (Hons) in Fashion Designing">
        B.Sc. (Hons) in Fashion Designing
      </option>
      <option value="B.Sc. (Hons) in Interior Design">
        B.Sc. (Hons) in Interior Design
      </option>
      <option value="Diploma in Fashion Designing (DFD)">
        Diploma in Fashion Designing (DFD)
      </option>
      <option value="Diploma in Interior Design (DID)">
        Diploma in Interior Design (DID)
      </option>
      <option value="Diploma in Product Design">
        Diploma in Product Design
      </option>
      <option value="Diploma in Jewellery Design">
        Diploma in Jewellery Design
      </option>
      <option value="Certificate in Fashion Designing (CFD)">
        Certificate in Fashion Designing (CFD)
      </option>
      <option value="Certificate in Interior Design (CID)">
        Certificate in Interior Design (CID)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Pharmacy">
        Doctor of Philosophy (Ph.D.) in Pharmacy
      </option>
      <option value="Master of Pharmacy (M Pharma)">
        Master of Pharmacy (M Pharma)
      </option>
      <option value="Bachelor of Pharmacy (B Pharma)">
        Bachelor of Pharmacy (B Pharma)
      </option>
      <option value="Diploma in Pharmacy (D Pharma)">
        Diploma in Pharmacy (D Pharma)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Life Science">
        Doctor of Philosophy (Ph.D.) in Life Science
      </option>
      <option value="M.Sc. in Bio Technology"> M.Sc. in Bio Technology </option>
      <option value="M.Sc. in Microbiology"> M.Sc. in Microbiology </option>
      <option value="B.Sc. (Hons) in Bio Technology">
        B.Sc. (Hons) in Bio Technology
      </option>
      <option value="B.Sc. (Hons) in Microbiology">
        B.Sc. (Hons) in Microbiology
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Yoga Science and Naturopathy">
        Doctor of Philosophy (Ph.D.) in Yoga Science and Naturopathy
      </option>
      <option value="M.Sc. in Yoga Science and Naturopathy">
        M.Sc. in Yoga Science and Naturopathy
      </option>
      <option value="M.A. in Yoga"> M.A. in Yoga </option>
      <option value="B.Sc. (Hons) in Yoga Science and Naturopathy">
        B.Sc. (Hons) in Yoga Science and Naturopathy
      </option>
      <option value="B.A. (Hons) in Yoga"> B.A. (Hons) in Yoga </option>
      <option value="Post Graduate Diploma in Yoga and Naturopathy">
        Post Graduate Diploma in Yoga and Naturopathy
      </option>
      <option value="Diploma in Yoga (DIY)"> Diploma in Yoga (DIY) </option>
      <option value="Diploma in Naturopathy (DN)">
        Diploma in Naturopathy (DN)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Law">
        Doctor of Philosophy (Ph.D.) in Law
      </option>
      <option value="Master of Law (LLM)"> Master of Law (LLM) </option>
      <option value="Bachelor of Law (LLB)"> Bachelor of Law (LLB) </option>
      <option value="Bachelor of Business Administration and Bachelor of Law (BBA LLB)">
        Bachelor of Business Administration and Bachelor of Law (BBA LLB)
      </option>
      <option value="Bachelor of Arts and Bachelor of Law (BA LLB)">
        Bachelor of Arts and Bachelor of Law (BA LLB)
      </option>
      <option value="Post Graduate Diploma in Law">
        Post Graduate Diploma in Law
      </option>
      <option value="Diploma in Law"> Diploma in Law </option>
      <option value="Doctor of Philosophy (Ph.D.) in Library and Information Science">
        Doctor of Philosophy (Ph.D.) in Library and Information Science
      </option>
      <option value="Master of Library and Information Science (M.Lib. & I.Sc.)">
        Master of Library and Information Science (M.Lib. & I.Sc.)
      </option>
      <option value="Bachelor of Library and Information Science (B.Lib. & I.Sc.)">
        Bachelor of Library and Information Science (B.Lib. & I.Sc.)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Journalism and Mass Communication">
        Doctor of Philosophy (Ph.D.) in Journalism and Mass Communication
      </option>
      <option value="Master of Arts in Journalism and Mass Communication (MA JMC)">
        Master of Arts in Journalism and Mass Communication (MA JMC)
      </option>
      <option value="Bachelor of Arts (Hons) in Journalism and Mass Communication (BA JMC)">
        Bachelor of Arts (Hons) in Journalism and Mass Communication (BA JMC)
      </option>
      <option value="Diploma in Journalism and Mass Communication (D JMC)">
        Diploma in Journalism and Mass Communication (D JMC)
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Education">
        Doctor of Philosophy (Ph.D.) in Education
      </option>
      <option value="Master of Arts in Education">
        Master of Arts in Education
      </option>
      <option value="Bachelor of Arts (Hons) in Education">
        Bachelor of Arts (Hons) in Education
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Hotel Management and Catering Technology">
        Doctor of Philosophy (Ph.D.) in Hotel Management and Catering Technology
      </option>
      <option value="Master of Hotel Management and Catering Technology (Proposed)">
        Master of Hotel Management and Catering Technology (Proposed)
      </option>
      <option value="Bachelor of Hotel Management and Catering Technology (BHMCT)">
        Bachelor of Hotel Management and Catering Technology (BHMCT)
      </option>
      <option value="Diploma in Hotel Management and Catering Technology (DHMCT)">
        Diploma in Hotel Management and Catering Technology (DHMCT)
      </option>
      <option value="Diploma in Culinary Arts">Diploma in Culinary Arts</option>
      <option value="Diploma in Travel & Tourism">
        Diploma in Travel & Tourism
      </option>
      <option value="M.Sc. in Medical Biotechnology (M.Sc. MBT)">
        M.Sc. in Medical Biotechnology (M.Sc. MBT)
      </option>
      <option value="Bachelor of Physiotherapy">
        Bachelor of Physiotherapy
      </option>
      <option value="B.Sc. (Hons) in Forensic Science">
        B.Sc. (Hons) in Forensic Science
      </option>
      <option value="B.Sc. (Hons) in Nutrition & Dietetics">
        B.Sc. (Hons) in Nutrition & Dietetics
      </option>
      <option value="B.A. (Hons) in Public Health">
        B.A. (Hons) in Public Health
      </option>
      <option value="Post Graduate Diploma in Nutrition and Dietetics">
        Post Graduate Diploma in Nutrition and Dietetics
      </option>
      <option value="Diploma in Dietetics"> Diploma in Dietetics </option>
      <option value="Diploma in Nutrition"> Diploma in Nutrition </option>
      <option value="Bachelor of Vocational Studies">
        Bachelor of Vocational Studies
      </option>
      <option value="Diploma in Vocational Studies">
        Diploma in Vocational Studies
      </option>
      <option value="Certificate in Vocational Studies">
        Certificate in Vocational Studies
      </option>
      <option value="Doctor of Philosophy (Ph.D.) in Media and Entertainment">
        Doctor of Philosophy (Ph.D.) in Media and Entertainment
      </option>
      <option value="M.A. in Film Making and Advertising">
        M.A. in Film Making and Advertising
      </option>
      <option value="M.A. in Public Relation and Event Management">
        M.A. in Public Relation and Event Management
      </option>
      <option value="B.A. (Hons) in Film Making and Advertising">
        B.A. (Hons) in Film Making and Advertising
      </option>
      <option value="B.A. (Hons) in Public Relation and Event Management">
        B.A. (Hons) in Public Relation and Event Management
      </option>
      <option value="Diploma in Photography"> Diploma in Photography </option>
      <option value="Diploma in Script Writing">
        Diploma in Script Writing
      </option>
      <option value="Diploma in Advertising"> Diploma in Advertising </option>
      <option value="Diploma in Branding"> Diploma in Branding </option>
      <option value="Diploma in VFX and Animation">
        Diploma in VFX and Animation
      </option>
      <option value="Masters of Arts in English">
        Masters of Arts in English
      </option>
      <option value="Master of Arts in Hindi"> Master of Arts in Hindi </option>
      <option value="Master of Arts in Chhattisgarhi">
        Master of Arts in Chhattisgarhi
      </option>
      <option value="Master of Arts in Philosophy">
        Master of Arts in Philosophy
      </option>
      <option value="Master of Arts in History and Archaeology">
        Master of Arts in History and Archaeology
      </option>
      <option value="Master of Arts in Politic Science">
        Master of Arts in Politic Science
      </option>
      <option value="Master of Arts in Public Administration">
        Master of Arts in Public Administration
      </option>
      <option value="Master of Arts in Economics">
        Master of Arts in Economics
      </option>
      <option value="Master of Arts in Education">
        Master of Arts in Education
      </option>
      <option value="Master of Arts in Sociology">
        Master of Arts in Sociology
      </option>
      <option value="Master of Arts in Geography">
        Master of Arts in Geography
      </option>
      <option value="Master of Arts in Psycology">
        Master of Arts in Psycology
      </option>
      <option value="Master of Arts in Sanskrit">
        Master of Arts in Sanskrit
      </option>
      <option value="Master of Arts in Disaster Management">
        Master of Arts in Disaster Management
      </option>
      <option value="Master of Arts in Public Health">
        Master of Arts in Public Health
      </option>
      <option value="M.Tech in Computer Science">
        M.Tech in Computer Science
      </option>
      <option value="M.Tech in Civil Engineering">
        M.Tech in Civil Engineering
      </option>
      <option value="M.Tech in Mechanical Engineering">
        M.Tech in Mechanical Engineering
      </option>
      <option value="M.Tech in Mining Engineering">
        M.Tech in Mining Engineering
      </option>
      <option value="M.Tech in Electronics Engineering">
        M.Tech in Electronics Engineering
      </option>
      <option value="M.Tech in E Security"> M.Tech in E Security </option>
      <option value="M.Tech in Computer Technology">
        M.Tech in Computer Technology
      </option>
      <option value="M.Tech in Software Engineering">
        M.Tech in Software Engineering
      </option>
      <option value="M.Tech in Structural"> M.Tech in Structural </option>
      <option value="M.Tech in Construction Technology & Management">
        M.Tech in Construction Technology & Management
      </option>
      <option value="M.Tech in Geo Technical Engineering">
        M.Tech in Geo Technical Engineering
      </option>
      <option value="M.Tech in Highway Engineering">
        M.Tech in Highway Engineering
      </option>
      <option value="M.Tech in Production"> M.Tech in Production </option>
      <option value="M.Tech in Energy Management">
        M.Tech in Energy Management
      </option>
      <option value="M.Tech in Thermal"> M.Tech in Thermal </option>
      <option value="M.Tech in Safety"> M.Tech in Safety </option>
      <option value="B.Tech in Civil Engineering">
        B.Tech in Civil Engineering
      </option>
      <option value="B.Tech in Mechanical Engineering">
        B.Tech in Mechanical Engineering
      </option>
      <option value="B.Tech in Electrical & Electronics Engineering">
        B.Tech in Electrical & Electronics Engineering
      </option>
      <option value="B.Tech in Computer Science">
        B.Tech in Computer Science
      </option>
      <option value="B.Tech in Mining Engineering">
        B.Tech in Mining Engineering
      </option>
      <option value="B.Tech in Information Technology">
        B.Tech in Information Technology
      </option>
      <option value="B.Tech in Chemical Engineering">
        B.Tech in Chemical Engineering
      </option>
      <option value="Diploma in Civil Engineering">
        Diploma in Civil Engineering
      </option>
      <option value="Diploma in Mechanical Engineering">
        Diploma in Mechanical Engineering
      </option>
      <option value="Diploma in Electrical & Electronics Engineering">
        Diploma in Electrical & Electronics Engineering
      </option>
      <option value="Diploma in Computer Science">
        Diploma in Computer Science
      </option>
      <option value="Diploma in Mining Engineering">
        Diploma in Mining Engineering
      </option>
      <option value="Diploma in Chemical Engineering">
        Diploma in Chemical Engineering
      </option>
    </>
  );
};

export default CourseList;
