import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import modi from "../../Images/commonimg/pm-modi.webp";
import fire from "../../Images/commonimg/fire-sefty.webp";
import img1 from "../../Images/commonimg/ISBMU_Campus_1.webp";
import img2 from "../../Images/commonimg/ISBMU_Campus_2.webp";
import img3 from "../../Images/commonimg/ISBMU_Campus_3.webp";
import img4 from "../../Images/commonimg/ISBMU_Campus_4.webp";
import img5 from "../../Images/commonimg/ISBMU_Campus_5.webp";
import img6 from "../../Images/commonimg/ISBMU_Campus_6.webp";
import img7 from "../../Images/commonimg/ISBMU_Campus_7.webp";
import img8 from "../../Images/commonimg/ISBMU_Campus_8.webp";
import img9 from "../../Images/commonimg/ISBMU_Campus_9.webp";

import EnquiryNow from "../common/EnquiryNow3";
 
const state = {
  responsive: {
    0: {
      items: 1,
    },
    450: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};
const Skilldevelopment = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <MetaTags>
            <title>ISBM University Chhattisgarh | Top University in India</title>
            <meta
              name="description"
              content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programmes and quality education nationwide."
            />
            <link
              rel="canonical"
              href="https://isbmuniversity.edu.in/pay-fees-online"
            />
            <meta
              name="SearchEngines"
              content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
          DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
            />
            <meta name="Robots" content="INDEX, FOLLOW" />
            <meta
              name="Author"
              content="ISBM University, isbmuniversity.edu.in/"
            />
            <meta name="Robots" content="INDEX,ALL" />
            <meta name="YahooSeeker" content="INDEX, FOLLOW" />
            <meta name="msnbot" content="INDEX, FOLLOW" />
            <meta name="googlebot" content="Default, follow" />
            <meta name="owner" content="isbmuniversity.edu.in/" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="2 days" />
            <meta
              property="og:title"
              content="ISBM University Chhattisgarh | Top University in India"
            />
            <meta
              property="og:description"
              content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programmes and quality education nationwide."
            />
            <meta
              property="og:url"
              content="https://isbmuniversity.edu.in/pay-fees-online/"
            />
            <meta
              property="og:image"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.webp"
            />
            <meta property="og:image:alt" content="ISBM University" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ISBM University" />
            
            <meta
              name="twitter:title"
              content="ISBM University Chhattisgarh | Top University in India"
            />
            <meta
              name="twitter:description"
              content="Discover excellence at ISBM University, a leading institution in Chhattisgarh and among the top universities in India. Offering diverse programmes and quality education nationwide."
            />
            <meta
              name="twitter:image:src"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.webp"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@ISBMUniversity" />
            <meta name="twitter:creator" content="@ISBMUniversity" />
            <meta
              name="twitter:domain"
              content="https://isbmuniversity.edu.in/"
            />
            <meta property="twitter:account_id" ycontent="758619542734053376" />
  
            <meta
              name="keywords"
              content="ISBM University, University in Chhattisgarh, Top Universities in India"
            />
          </MetaTags> */}

      <Topnav />
      <Menubar />
      <div className="skillinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1> Skill Development</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active> Skill Development</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123"> Skill Development</h2>
            <h5 className="linkabout">
              We make VOCATIONAL TRAINING inspirational to transform INDIA into
              the SKILL CAPITAL of the world.
            </h5>
            <p>
              In partnership with the Ministry of Skill Development &
              Entrepreneurship (MSDE) and National Skill Development Corporation
              (NSDC), we have launched a state-of-the-art Model Training Centre
              (MTC), called Pradhan Mantri Kaushal Kendra (PMKK) in Gariyabandh,
              Chhattisgarh.
            </p>
            <Row>
              <img
                src={modi}
                alt="Best university for professional Studies"
                style={{ width: "400px" }}
              ></img>
              <img
                src={fire}
                alt="Best University for Information and Technology"
                className="ms-4"
                style={{ width: "400px" }}
              ></img>
            </Row>
            <p className=" mt-3">
              We in association with the Ministry and NSDC, are playing a
              pivotal role in creating benchmark institutions that demonstrate
              aspirational value for competency based skill development training
              by focusing on elements of quality, sustainability and connect
              with youth. By addressing the challenges of unemployment and
              ensuring gainful employment for the youth of the state, we are
              enabling and mobilizing Chhattisgarh youth to make them employable
              and work ready.
            </p>
            <p >
              The Pradhan Mantri Kaushal Kendra run by us in Gariyabandh,
              Chhattisgarh will be offering several courses in Agriculture i.e.
              Gardening, Micro-irrigation Technician and Assistant Electrician
              under Civil construction and Plumbing and Sewing Machine Operator.
              These courses have been approved by the Ministry of Skill
              Development & Entrepreneurship (MSDE).
            </p>
            <p >
              We are laying special emphasis on enhancing the personality and
              soft skills of candidates to enhance their employability quotient
              and entrepreneurship skills. Moreover, additional investment has
              been made to impart training on entrepreneurship skills. Besides a
              recreation zone has been set up alongwith a special interview cell
              and an up-to-date library. Additionally to further motivate and
              provide confidence to the candidates, a counselling cum placement
              cell has been made part of the Kaushal Kendra.
            </p>
            <p>
              Skills development is now a national priority and India has a long
              way to go in realizing the benefits of its demographic dividend.
              While the state contributed heavily towards the green revolution
              and made nation self-sufficient in food crops production in
              1960’s, there still remain large diaspora of unemployed youth at
              any point of time. This is due to the dearth of industry specific
              skills and domain knowledge in such mainstream education. To
              bridge this employability gap, we are providing livelihood
              training for rural youth to prepare them with hands-on skills
              required for self or wage employment to skill and make them
              job-ready.
            </p>
            <p >
              <b>ISBM University Offers:</b>
            </p>
            <ul className="skills">
              <li>Soft Skill Development</li>
              <li>Computer Hardware Assistant</li>
              <li>Computer Network Assistant</li>
              <li>Risk Management &amp; Insurance</li>
              <li>Retail Management</li>
              <li>Managing Retail</li>
              <li>Financial Services</li>
              <li>General Bank Management</li>
            </ul>

            <OwlCarousel
              margin={20}
              autoplay
              nav={false}
              dots={false}
              items={3}
              touchDrag={true}
              responsive={state.responsive}
            >
              <div className="item">
                <img src={img1} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img2} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img3} className="img-fluid "></img>
              </div>
              <div className="item">
                <img src={img4} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img5} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img6} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img7} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img8} className="img-fluid"></img>
              </div>
              <div className="item">
                <img src={img9} className="img-fluid"></img>
              </div>
            </OwlCarousel>

            <h5 >
              <b>PMKK Gariyabandh Chhattisgarh</b>
            </h5>

            <p >
              <b>Skills Development:</b>
            </p>
            <ul  className="skills">
              <li>Excel</li>
              <li>English Speaking</li>
              <li>Social Media</li>
              <li>Advance English</li>
              <li>Advance Tally</li>
              <li>GST</li>
              <li>Power Point</li>
              <li>Word</li>
              <li>Interview Skills</li>
              <li>Sales</li>
              <li>Iphone Features</li>
              <li>Android Features</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Skilldevelopment;
