import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import library from "../../Images/commonimg/library.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Library = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="libraryinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Library</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Library</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Library</h2>
            <img
              src={library}
              alt="Modern interior university library with seating areas"
              style={{ marginBottom: "20px" }}
            ></img>
            <p>
              The Central Library at ISBM University is an essential resource
              for students, faculty, and researchers, offering a comprehensive
              collection of academic materials and services. With an extensive
              range of books, journals, and research papers across various
              disciplines, the library ensures that users have access to
              up-to-date information for their studies. In addition to physical
              resources, the library provides digital access to e-books, online
              journals, and databases, facilitating efficient research.
              Comfortable study spaces, including individual carrels and group
              study rooms, create an inviting environment for focused learning,
              while dedicated reading areas promote quiet reflection. The
              library staff offers reference services to assist with research
              inquiries, enhancing the user experience. The Central Library at
              ISBM University serves as a vital hub for academic growth and
              research support within the University community.
            </p>
            <p>
              {" "}
              <strong className="text-blue">
                The Key features of the Central Library include:
              </strong>
            </p>
            <ol>
              <li>
                <strong className="text-blue">Extensive Collection:</strong>
                <span>
                  {" "}
                  The library houses a diverse range of books, journals, and
                  research papers across various disciplines, ensuring that
                  students have access to up-to-date academic resources.
                </span>
              </li>
              <li>
                <strong className="text-blue">Digital Resources:</strong>
                <span>
                  {" "}
                  In addition to physical books, the library offers access to a
                  variety of digital resources, including e-books, online
                  journals, and databases, enabling students to conduct research
                  efficiently.
                </span>
              </li>
              <li>
                <strong className="text-blue">Study Spaces:</strong>
                <span>
                  {" "}
                  The library provides comfortable study areas, including
                  individual study carrels and group study rooms, allowing
                  students to focus on their work in a conducive environment.
                </span>
              </li>
              <li>
                <strong className="text-blue">Reading Rooms:</strong>
                <span>
                  {" "}
                  Dedicated reading areas promote quiet study and reflection,
                  encouraging students to immerse themselves in their studies.
                </span>
              </li>
              <li>
                <strong className="text-blue">Reference Services:</strong>
                <span>
                  {" "}
                  Library staff are available to assist students with research
                  inquiries, helping them navigate the extensive collection and
                  find relevant materials.
                </span>
              </li>
              <li>
                <strong className="text-blue">
                  Workshops and Training:
                </strong>
                <span>
                  {" "}
                  The library often organizes workshops on research skills,
                  information literacy, and effective use of library resources
                  to help students enhance their academic abilities.
                </span>
              </li>
            </ol>
            <p>
              The Central Library at ISBM University is a crucial hub for
              academic growth and research, supporting the educational
              endeavours of the University community.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Library;
