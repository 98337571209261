import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import resize1 from "../../Images/commonimg/resize1.webp";
import resize2 from "../../Images/commonimg/resize2.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Laboratory = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="laboratoryinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Laboratory</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Laboratory</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Laboratory</h2>

            <p>
              ISBM University features a variety of laboratories designed to
              enhance practical learning and research across different academic
              disciplines. Here are some key laboratories available at the
              University:
            </p>
            <ol>
              <li>
                <strong className="text-blue">Computer Lab:</strong>
                <span>
                  {" "}
                  Equipped with modern computers and software, the computer lab
                  supports courses in programming, data science, and IT-related
                  subjects, allowing students to gain hands-on experience in
                  their field.
                </span>
              </li>
              <li>
                <strong className="text-blue">Engineering Lab:</strong>
                <span>
                  {" "}
                  This lab caters to engineering students, offering tools and
                  equipment for civil, mechanical, and electrical engineering
                  projects. It provides practical exposure through experiments
                  and project work.
                </span>
              </li>
              <li>
                <strong className="text-blue">Management Lab:</strong>
                <span>
                  {" "}
                  Designed for management students, this lab utilizes
                  simulations to teach business concepts and decision-making
                  skills, helping students apply theoretical knowledge in
                  real-world scenarios.
                </span>
              </li>
              <li>
                <strong className="text-blue">Language Lab:</strong>
                <span>
                  {" "}
                  This facility focuses on improving language proficiency
                  through interactive learning tools, helping students enhance
                  their communication skills in various languages.
                </span>
              </li>
              <li>
                <strong className="text-blue">Research Lab:</strong>
                <span>
                  {" "}
                  The research lab provides space for student and faculty
                  research projects, fostering innovation and exploration across
                  different academic fields.
                </span>
              </li>
              <li>
                <strong className="text-blue">Science Labs:</strong>
                <span>
                  {" "}
                  These labs, including Chemistry, Biology, Zoology, and Physics
                  labs, are designed for conducting experiments and practical
                  work, promoting scientific inquiry and understanding.
                </span>
              </li>
              <li>
                <strong className="text-blue">Pharmaceutical Labs:</strong>
                <span>
                  {" "}
                  The Pharmacology, Pharmaceutics, Pharmaceutical Chemistry,
                  Pharmacognosy, and Analysis labs are the core labs available
                  in the Pharmacy department to get complete practical exposure.
                </span>
              </li>
            </ol>
            <p>
              Each laboratory at ISBM University is equipped with the necessary
              resources and staffed by qualified faculty, ensuring students
              receive a comprehensive educational experience that combines
              theoretical knowledge with practical application.
            </p>
            <Row>
              <Col>
                <img
                  src={resize1}
                  alt="best msc colleges in india"
                  height="300px"
                ></img>
              </Col>
              <Col>
                <img
                  src={resize2}
                  alt="Best laboratory Colleges"
                  height="300px"
                ></img>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Laboratory;
