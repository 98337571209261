import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow3";
import Allinone from "../common/Allinone.js";

const Research = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="researchinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Research</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Research</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Research</h2>
            <p className="detail_text">
              Research at ISBM University is a cornerstone of its academic and
              institutional mission, reflecting a commitment to innovation,
              knowledge creation, and societal advancement. The University
              fosters a vibrant research culture across various disciplines,
              emphasizing both basic and applied research. Research at ISBM
              University embodies a dynamic and innovative spirit, driving
              forward knowledge and societal progress through rigorous and
              impactful investigations. The University fosters a collaborative,
              interdisciplinary research environment, encouraging partnerships
              across various faculties and with external organizations to
              address complex global challenges
            </p>
            <p>
              Key aspects of the research environment at ISBM University
              include:
            </p>
            <ul>
              <li>
                <strong className="text-blue">
                  1. Interdisciplinary Approach:
                </strong>{" "}
                Encouraging collaboration across different faculties and
                departments to address complex, multifaceted problems, resulting
                in innovative solutions and comprehensive insights.
              </li>
              <li>
                <strong className="text-blue">
                  2. State-of-the-Art Facilities:
                </strong>{" "}
                Providing researchers with access to modern laboratories,
                advanced equipment, and cutting-edge technology to support
                high-quality research endeavours.
              </li>
              <li>
                <strong className="text-blue">3. Experienced Faculty:</strong>{" "}
                Engaging a team of distinguished scholars and researchers who
                guide and mentor students, helping them to develop their
                research skills and pursue impactful projects.
              </li>
              <li>
                <strong className="text-blue">
                  4. Publication and Dissemination:
                </strong>{" "}
                Promoting the dissemination of research findings through
                publications in reputable journals, presentations at
                international conferences, and participation in academic forums.
              </li>
              <li>
                <strong className="text-blue">
                  5. Partnerships and Collaborations:
                </strong>{" "}
                Building strong connections with industry, government agencies,
                and other academic institutions to foster collaborative research
                initiatives and apply research outcomes to real-world
                challenges.
              </li>
              <li>
                <strong className="text-blue">6. Student Involvement:</strong>{" "}
                Encouraging student participation in research projects to
                enhance their learning experience, develop critical thinking
                skills, and prepare them for careers in academia, industry, or
                other research-intensive fields.
              </li>
              <li>
                <strong className="text-blue">7. Ethical Standards:</strong>{" "}
                Upholding the highest ethical standards in research, ensuring
                integrity, transparency, and accountability in all research
                activities.
              </li>
            </ul>
            <p className="fs-3 text-secondary">
              Outcome of the Research Activities in the University{" "}
            </p>
            <p>
              The Doctor of Philosophy (Ph.D.) programs will give an opportunity
              to the students to undertake advanced studies in the subject in
              which he or she has already acquired post-graduation.
            </p>
            <div id="outcomes" className="outcome">
              <ul>
                <li>
                  These programs are focused on creating free
                  thinkers—individuals who are independent in their thinking,
                  loyalty, and commitment.
                </li>
                <li>
                  The prime focus of the programs offered is to promote the
                  effective integration of technology with state-of-the-art
                  facilities in teaching and research activities.
                </li>
                <li>
                  The curriculum offered and learning pedagogy focuses on
                  problem-solving and decision-making, thus creating honed
                  analytical skills coupled with knowledge and application of
                  various emerging trends in the fields of Management &
                  Commerce, Science, Arts, and Humanities.
                </li>
                <li>
                  The broad objectives of the research program are to keep pace
                  with the expanding frontiers of knowledge in conformity with
                  the requirements of mankind.
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Allinone />
      <Footer />
    </>
  );
};

export default Research;
