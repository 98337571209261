// 404Page.js
import React from "react";
import { MetaTags } from "react-meta-tags";

const NotFoundPage = () => {
  return (
    <React.Fragment>
      <MetaTags>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <section className="error-page section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="error-inner">
                <h1>
                  404<span>Oop's sorry we can't find that page!</span>
                </h1>
                <a
                  className="btn btn-primary"
                  href="https://isbmuniversity.edu.in/"
                >
                  Go Back
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default NotFoundPage;
