import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Recruiters = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="recruitersinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Recruiters</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Recruiters</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Recruiters</h2>
            <p>
              At ISBM University, we take pride in our strong connections with a
              wide array of esteemed companies and organizations that actively
              participate in our campus recruitment efforts. Our strong
              placement network helps to unlock a plethora of opportunities for
              our students while giving them valuable insight into various
              industries and job profiles.
            </p>
            <h5 className="text-purple fw-bold">Diverse Industry Partnership : </h5>
            <p>
              We have established tie-ups with leading companies across multiple
              sectors, including :
            </p>
            <p>
              <strong className="text-blue">Information Technology:</strong>We
              have partnerships with leading technology firms to provide our
              students with various roles in software development, data
              analytics, cybersecurity, and IT consultancy.
            </p>
            <p>
              <strong className="text-blue">Finance and Banking:</strong>We also
              have close links with financial institutions and banks that
              present the student with opportunities in investment banking,
              financial analysis, and risk management.
            </p>
            <p>
              <strong className="text-blue">
                Manufacturing and Engineering:
              </strong>
              We also partner with leading manufacturing firms to provide routes
              into production management, quality assurance, and indeed
              engineering roles for our students.
            </p>
            <p>
              <strong className="text-blue">Healthcare:</strong>Our close
              associations with healthcare organizations open up further
              opportunities for our students in health care management,
              pharmaceutical sales, and health information technology.
            </p>
            <p>
              <strong className="text-blue">Retail and E-commerce:</strong>
              Through connections with big-box retailers and leading e-commerce
              sites, students can participate in tasks surrounding supply chain
              management, marketing, and sales.
            </p>
            <p>
              <strong className="text-blue">Consulting:</strong>Our close links
              with consulting firms open up opportunities for our graduates in
              management consulting, business analysis, and strategic planning.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Recruiters;
