import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import bustakes from "../../Images/commonimg/bustakes.webp";
import EnquiryNow from "../common/EnquiryNow3";
 
const Busrouteschedule = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
      <Topnav />
      <Menubar />
      <div className="businnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Bus Routes And <br /> Schedules</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Bus Routes And Schedules</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Bus Routes And Schedules</h2>
            <Row>
              <Col lg={6}>
                <img
                  src={bustakes}
                  alt="University shuttle bus route"
                  className="w-100"
                />
              </Col>
              <Col lg={6}>
              <h5 className="text-darkblue"><b>Unsure About Which Bus To Take?</b></h5>
                <p>
                  The easiest way to plan your trip to our campus using transit
                  - especially if you're unsure about which route to take or
                  what time to take it - is to use the ISBM University Bus
                  Schedule penned below and to contact our city office (+91
                  93299 32747) in case of any queries. Alternatively, go to
                  maps.google.com and use the Directions tool (be sure to
                  specify "transit" as your mode of travel)!
                </p>
                <p>
                  The trip planner will automatically select the best route for
                  you to take, based on your origin and destination - it will
                  even suggest trips operated by local service providers.
                </p>
              </Col>
              <h5 className="text-darkblue mt-5"><b>Already Know The System?</b></h5>
              <p>
                Below is a list of ISBM University bus routes, some common
                destinations served by the route, and the service periods and
                times. Click here for a map and look below full schedule of the
                route.
              </p>
            </Row>
            <Row>
              <Col lg={6}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-white">STOPS</th>
                      <th className="text-white">NEXT BUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td id="bustd">Churra</td>
                      <td id="bustd">6.00 AM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Fingeshwar</td>
                      <td id="bustd">7.00 AM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Mahasamund</td>
                      <td id="bustd">7.55 AM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Khatti Khamariya</td>
                      <td id="bustd">8.30 AM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Churra</td>
                      <td id="bustd">9.25 AM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Campus</td>
                      <td id="bustd">3.30 PM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Churra</td>
                      <td id="bustd">4.00 PM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Fingeshwar</td>
                      <td id="bustd">4.45 PM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Mahasamund</td>
                      <td id="bustd">5.15 PM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Khatti Khamariya</td>
                      <td id="bustd">6.00 PM</td>
                    </tr>
                    <tr>
                      <td id="bustd">Churra</td>
                      <td id="bustd">7.00 PM</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col lg={6} className="mt-6">
                <p>For instant arrivals via phone call, Dial +91 93299 32747</p>
                <p>
                  Questions, comments or concerns? Contact us at
                  support@isbmuniversity.edu.in
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Busrouteschedule;
