import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import jayendra from "../../Images/Hon'ble Persons/Dr. Jayendra Narang.webp";
import EnquiryNow from "../common/EnquiryNow3";



const Directorgeneral = () => {
  const [modalShow, setModalShow] = React.useState(false);
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
    return (
      <>
     
        <Topnav />
        <Menubar />
        <div className="Visitorinnerbackimage innerbackimage">
          <div className="text_banners_abcds">
            <h1>Director General</h1>
            <div  onClick={() => setModalShow(true)}>
              <span>
                <a className="btn btn-primary" href="#" role="button">
                  Apply Now
                </a>
              </span>
            </div>
            <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Director General</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Sidebaar />
            <Col lg={9}>
              <Row>
                <Col lg={4}>
                  <div className="honor_div">
                    <img className="img_honor" src={jayendra} alt="Director General Dr. Jayendra Narang"/>
                  </div>
                </Col>
                <Col lg={8}>
                  <h2 className="honor_name">Dr. Jayendra Narang</h2>
                  <p className="text-justify">Director General speaks,</p>
                  <p className="text-justify">
                    Preparedness, foresight and strategy are the tools required
                    to embark the journey of success. We started our journey
                    with these three forces combine with the efforts of cohesive
                    and well knitted network of academicians, researchers,
                    professionals and industrialists. It gives me great sense of
                    satisfaction and pleasure to see the way we have been
                    accepted. Our institute strives for imparting integral &
                    impartial education to the students. We understand that
                    education now a day is not a mundane process restricted to
                    chalks and talks. We have taken all efforts to replace it
                    with multi- directional group workshop methods combine with
                    high technology and excellent infrastructure. We believe at
                    harnessing the tremendous potential of technology to the
                    maximum utilization. We firmly believe that academia and the
                    academic institutions are the fulcrum of any growth -be it
                    human, economic and socio-economic. We leave no stone
                    unturned in chiseling and refining our professional skills
                    to prove the authenticity of the dictum. The success and
                    eminence of ISBM University began as a dream of one man
                    extended into dream of many. We strive to accomplish our
                    dreams and goals to bring greater effulgence in the future.
                    A set of core values continue to guide us through the
                    process of transforming the conglomerate into the high
                    performing and caring organization for parents, students,
                    employees, society and nation.
                  </p>
                  <p className="text-justify">
                  Dr. Jayendra Narang
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }


export default Directorgeneral;
