import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import overview1 from "../../Images/commonimg/campus-overview1.webp";
import overview2 from "../../Images/commonimg/campus-overview2.webp";
import campus from "../../Images/commonbanner/ISBMU_Campus.webp";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Overview = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="overviewinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Overview</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Overview</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Campus Overview</h2>
            <p className="text-justify">
              ISBM University boasts a modern and vibrant campus that is
              designed to facilitate an engaging learning environment. Situated
              in a spacious setting, the campus features state-of- the-art
              facilities, including well-equipped classrooms, advanced
              laboratories, and dedicated study areas. The University also
              provides ample resources for research and innovation, including
              libraries stocked with a wide range of academic materials.
            </p>
            <p className="text-justify">
              The sprawling 26 acre campus of ISBM University is nestled in the
              heart of nature. The sylvan surroundings of the campus amidst the
              forest and hills, provide an inspiring learning environment for
              everyone. Blessed with the divinity of Tenganhi Mata (Durga Maa)
              Temple in its neighbourhood, the campus is complete with all
              modern facility and features.
            </p>
            <p className="text-justify">
              In addition to academic facilities, the campus offers recreational
              areas, sports facilities, and spaces for extracurricular
              activities, promoting a well-rounded student experience. Students
              can participate in various clubs and organizations that foster
              community and collaboration. The University's commitment to a
              supportive and inclusive environment is reflected in its
              infrastructure, which is designed to accommodate diverse needs.
              Overall, ISBM University’s campus serves as a hub for academic
              excellence, personal growth, and social interaction, making it an
              ideal place for students to thrive.
            </p>
            <Row>
              <Col>
                <img
                  src={overview1}
                  alt="Best colleges in india"
                  className="img-fluid"
                  style={{ marginBottom: "20px" }}
                ></img>
              </Col>
              <Col>
                <img
                  src={overview2}
                  alt="Best colleges in india"
                  className="img-fluid"
                  style={{ marginBottom: "20px" }}
                ></img>
              </Col>
            </Row>
            <div>
              <p className="text-blue fw-bold">
                ISBM University, located in India, has several important
                features on its campus:
              </p>
              <ol type="1">
                <li>
                  <strong className="text-blue">Infrastructure:</strong>
                  <span>
                    The campus is equipped with modern facilities, including
                    lecture halls, seminar rooms, and auditoriums designed for a
                    conducive learning environment.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Library:</strong>
                  <span>
                    A well-stocked library with a vast collection of books,
                    journals, and digital resources is available for students.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Laboratories:</strong>
                  <span>
                    Specialized labs for various fields of study, such as
                    computer science, engineering, and management, allow
                    students to gain practical experience.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Sports Facilities:</strong>
                  <span>
                    ISBM provides various sports facilities, including fields
                    for cricket, football, basketball, and indoor games,
                    promoting a healthy lifestyle.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Hostel Accommodation:</strong>
                  <span>
                    The University offers comfortable hostel facilities for
                    students, providing a supportive community living
                    environment.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Cafeteria:</strong>
                  <span>
                    A cafeteria serving a variety of meals and snacks ensures
                    students have access to nutritious food on campus.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">
                    Extracurricular Activities:
                  </strong>
                  <span>
                    The campus encourages student participation in clubs,
                    cultural events, and seminars, enhancing overall
                    development.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Placement Cell:</strong>
                  <span>
                    A dedicated placement cell assists students in career
                    counseling and securing internships and job placements.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Green Campus:</strong>
                  <span>
                    Efforts are made to maintain a green environment with
                    landscaped gardens and eco-friendly practices.
                  </span>
                </li>
                <li>
                  <strong className="text-blue">Accessibility:</strong>
                  <span>
                    The campus is designed to be accessible to all students,
                    including those with disabilities.
                  </span>
                </li>
              </ol>

              <p className="mt-2">
                These features collectively create a vibrant and engaging
                educational atmosphere for students at ISBM University.
              </p>
            </div>
            <img
              src={campus}
              style={{ marginBottom: "20px" }}
              alt="One of the best university in India."
            ></img>

            <p className="text-justify">
              At ISBM University, the integration of laboratories and sports
              facilities plays a vital role in fostering a holistic educational
              experience for students. The University features a range of modern
              laboratories, including well-equipped computer labs, engineering
              labs for hands-on experimentation, and specialized spaces for
              management simulations, media production, language learning, and
              scientific research. These labs enable students to gain practical
              experience and apply theoretical knowledge in their respective
              fields. On the sports front, ISBM University boasts extensive
              outdoor and indoor facilities, including fields for cricket and
              football, basketball and badminton courts, and a fully equipped
              gymnasium. The athletics track and spaces available for games
              encourage students to engage in physical activities, promoting
              health, teamwork, and discipline. Additionally, yoga and
              meditation facilities support mental well-being, while various
              sports clubs and teams foster a spirit of competition and
              camaraderie. Together, the laboratories and sports facilities
              create a balanced environment that nurtures both academic
              excellence and physical fitness at ISBM University.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Overview;
