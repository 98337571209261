import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Table } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AdmissionSlidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const ProgrammesAndEligibilities = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Programmes and Eligibilities</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Programmes and Eligibilities</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AdmissionSlidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Programmes and Eligibilities</h2>
            <p>
              ISBM University encompasses a broad spectrum of knowledge, skills,
              and competencies aimed at ensuring students are well-prepared for
              professional and academic success.
            </p>

            <h5 className="text-purple">
              Knowledge Acquisition and Application
            </h5>
            <ul>
              <li>
                <strong className="text-blue">
                  In-depth Subject Knowledge:
                </strong>{" "}
                Students gain comprehensive knowledge in their chosen fields,
                whether it is engineering, management, arts, sciences, or law.
                This includes understanding fundamental concepts, theories, and
                practical applications.
              </li>
              <li>
                <strong className="text-blue">Research Skills:</strong>{" "}
                Particularly for postgraduate and doctoral students, ISBM
                University emphasizes research skills, enabling students to
                conduct independent research, analyze data, and contribute to
                their fields with original findings.
              </li>
            </ul>

            <h5 className="text-purple">Skill Development</h5>
            <ul>
              <li>
                <strong className="text-blue">
                  Technical and Analytical Skills:
                </strong>{" "}
                Programs such as B.Tech, B.Sc, and M.Sc ensure that students
                acquire strong technical skills relevant to their disciplines.
                For instance, students in computer science programs learn
                programming, data analysis, and software development.
              </li>
              <li>
                <strong className="text-blue">Soft Skills:</strong> The
                University also focuses on developing essential soft skills,
                including communication, teamwork, leadership, and
                problem-solving, which are crucial for career success across
                various industries.
              </li>
            </ul>

            <h5 className="text-purple">Professional Preparedness</h5>
            <ul>
              <li>
                <strong className="text-blue">
                  Industry-Relevant Curriculum:
                </strong>{" "}
                ISBM University designs its curriculum in alignment with
                industry requirements, ensuring that graduates are job-ready.
                This includes practical training, internships, and exposure to
                real-world projects.
              </li>
              <li>
                <strong className="text-blue">
                  Ethical and Social Responsibility:
                </strong>{" "}
                Students are educated on professional ethics and social
                responsibility, preparing them to make ethical decisions in
                their professional lives and contribute positively to society.
              </li>
            </ul>

            <h5 className="text-purple">Personal Growth</h5>
            <ul>
              <li>
                <strong className="text-blue">
                  Critical Thinking and Creativity:
                </strong>{" "}
                The academic environment at ISBM University fosters critical
                thinking and creativity, encouraging students to think
                independently and innovatively.
              </li>
              <li>
                <strong className="text-blue">Lifelong Learning:</strong>{" "}
                Graduates are instilled with the value of lifelong learning,
                equipping them with the ability to continuously adapt and grow
                in their careers and personal lives.
              </li>
            </ul>

            <p>
              Overall, ISBM University aims to produce well-rounded individuals
              who are not only experts in their fields but also possess the
              skills and ethical grounding to excel in various professional
              settings and contribute to society positively.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProgrammesAndEligibilities;
