import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const ReadMore = ({ children, wordLimit = 20 }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const words = text.split(" ");

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <>
      <p className="text_feature">
        {isReadMore ? words.slice(0, wordLimit).join(" ") : text}
      </p>
      {words.length > wordLimit && (
        <button onClick={toggleReadMore} className="read-more">
          {isReadMore ? "Read More" : "Show Less"}
        </button>
      )}
    </>
  );
};

const Aboutplacements = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="placementinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>About Placements</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>About Placements</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              Training and Placement Cell at ISBM University
            </h2>
            <div className="container">
              <p>
                Training & Placement Department has an important role to furnish
                and groom the future of students. We act as an interface among
                students and the employment community. We Empower students with
                life long career decision making skills. This department
                continuously strives to support students in pursuing their
                career goals by acquiring employment-seeking skills and
                ultimately to attain desired employment. This is accomplished
                through building a strong bridge between Industry and Academia.
              </p>
              <p>
                Training and Placement coordinators are the official arbitrators
                for campus placement in ISBM University.It’s enthusiastic team
                strives for effectively striking a match between recruiter
                expectations and Student aspirations.Training and Placement cell
                is a support system to help the students realize their full
                potential and accomplish their career aspirations.
              </p>
              <p>
                Training and Placement Office, student & Faculty Co-ordinators
                handle various crucial tasks in the office. The Placement team
                consists of the Placement Managers,trainers,student
                representatives & Faculty Placement Coordinators of respective
                branches who primarily coordinate Training & Placements
                activities on two paths :{" "}
              </p>
              <p>
                <strong className="text-blue">Campus Placements:</strong> the
                company communication which is executed by the Placement
                Officers and
              </p>
              <p>
                {" "}
                <strong className="text-blue">
                  Student Employability Enhancement:
                </strong>
                which is looked after by the Department Placement Coordinators.
              </p>
              <p>
                Training & Placement Office is well equipped with excellent
                infrastructure to support every stage of the Training &
                placement process.To offer career management services that
                provides students the necessary Insights and facilities to
                develop their technical competence and leadership skills, and
                thus facilitate a match between recruiter expectations and
                student aspirations.
              </p>
              <h5 className="text-purple fw-bold">Objectives</h5>
              <p>
                Placement Cell is to look for 100% Placements of all the
                students. It plans to organize various activities in the
                college, the most popular one being the 'Campus Recruitment' to
                provide our students with job opportunities.
              </p>
              <ul className="cocli">
                <li>
                  Empower students with lifelong career decision making skills.
                </li>
                <li>
                  Generation of awareness in the students regarding future
                  career options available to them.
                </li>
                <li>
                  Assist students to develop and implement successful job search
                  strategies.
                </li>
                <li>
                  Up gradation of the students skill sets commensurate with the
                  expectations of the industry.
                </li>
                <li>
                  Assist students to develop/clarify their academic and career
                  interests, and their short and long term goals through
                  individual counselling and group discussions.
                </li>
                <li>Career Development Program.</li>
                <li>
                  Arranging guest lecturers of Eminent Personalities from
                  Industry for career counselling.
                </li>
                <li>
                  Motivating the students by providing the necessary help and
                  guidance for Soft skills and Personality Development.
                </li>
              </ul>
              <h5 className="text-purple fw-bold">Training and Placement Activities</h5>

              <p>
                Career Services Office is an integral part of ISBM University.
                Activities of Career Services Office are mainly categorized into
                three domains, namely-
              </p>
              <p>
                Students undergo structured training programs to ensure they get
                their dream job and excel in their professional career.
                Companies of repute hold their campus drive at the university
                every year.
              </p>
              <p>
                The placement season in ISBM, begins in the month of September
                and goes on up to June. However, our office is a place buzzing
                with ideas, activities and enthusiasm throughout the year. We
                carry out the following activities on campus:
              </p>
              <p>
                <strong className="text-blue">
                  Training & Placement Database:
                </strong>{" "}
                Career Placement Office Maintains & Update the training &
                placement database of current & subsequent batches in industry
                specific format to execute any virtual or Physical campus
                recruitment drives.
              </p>
              <p>
                <strong className="text-blue">Resume Preparation:</strong>{" "}
                Resumes submitted for campus placement by the students are duly
                verified for every claim made. The verification standards are
                uniform throughout the Institute.
              </p>
              <p>
                <strong className="text-blue">
                  Career & Employability Assessment:
                </strong>{" "}
                the Gap Analysis of Employability skills is done by the training
                & Placement Office to suggest the appropriate training need &
                make the graduates a first day productive employees for the
                companies.
              </p>
              <p>
                The ultimate objective of career service office is to ensure
                that the students are industry ready and build career as per
                their aspirations.The office is strengthened by team of
                professionals who have strong experience of working with the
                student fraternity and corporate connect.
              </p>

              <h5 className="text-purple fw-bold">Centre for Higher Studies</h5>
              <p>
                Fosters a network among universities, institutions abroad and
                imparts higher education and collaborative study programs to
                prepare the students for the exams like GATE, GRE, CAT, GMAT &
                UPSC.
              </p>

              <h5 className="text-purple fw-bold">Centre for Entrepreneurship</h5>
              <p>
                Facilitates the students in bringing out innovation and empowers
                them to start their own ventures. It also support students to
                participate in government sponsored skill development projects.
              </p>

              <h5 className="text-purple fw-bold">Employability Enhancement Program</h5>
              <p>
                We aim to give our students the fine-tuning that they require
                not just for placements but also as they embark on their
                corporate careers. Training & Placement Office Follows a four
                year training modules divided in foundation, intermediate , and
                advanced skills off trending technologies. With this goal in
                mind, we carry out several employability enhancement activities
                that include :
              </p>
              <ul className="cocli">
                <li>Communication evaluation sessions</li>
                <li>
                  Preparatory activities like mock tests and group discussion
                </li>
                <li>
                  Career Counselling Sessions to inform and educate students
                  about the various job opportunities available in the corporate
                  world.
                </li>
                <li>
                  Group Discussions to provide training and practice to the
                  students about in-group behaviour and leadership skills.
                </li>
                <li>
                  Presentation Skills and Workshops to enhance communication
                  techniques.
                </li>
                <li>
                  Technical and Non Technical Training and Tests to revive
                  knowledge and basic understanding of the subjects.
                </li>
                <li>Industrial visits for experiential learning</li>
                <li>Summer projects</li>
                <li>Short term live projects offering hands on learning</li>
                <li>Personality development training</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Aboutplacements;
