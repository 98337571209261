import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import mom1 from "../../Images/Ordinances andStatute/MOM of AC meeting 25112022.pdf";
import EnquiryNow from "../common/EnquiryNow3";


const Academiccouncil = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <Topnav />
      <Menubar />
      <div className="comminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1> Academic Council</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active> Academic Council</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <p>
              The Academic Council is the academic body of the University; it
              coordinates and exercises supervision over the policies and
              programmes of the University. The Vice-Chancellor of the
              University is the chairman of the council, presiding over the
              meetings. It is composed of Deans, Heads of Departments,
              Scientists, Academicians, Technocrats, and Industrialists, all of
              whom are nominated by the Chancellor.
            </p>
            <p>Powers and functions of the Academic Council:</p>

            <ol type="1">
              <li>
                Co-opting members with knowledge and experience in the
                particular domain for academic transactions.
              </li>
              <li>
                Teaching, research, and related activities are promoted at the
                University.
              </li>
              <li>
                General supervision is exercised over the academic policies and
                programmes of the university.
              </li>
              <li>
                Directions are provided for the method of undertaking research
                and improving academic standards to facilitate academic
                transactions.
              </li>
              <li>
                Proposal to the Governing Body to allocate funds for conducting
                research.
              </li>
              <li>
                Proposal to the Governing Body for instituting fellowships,
                scholarships, exhibitions, medals, and prizes, and for making
                rules for their implementation.
              </li>
              <li>
                Recognizing persons of eminence in the subject domains to be
                associated as research guides/co-guides in the interdisciplinary
                and multi-disciplinary subjects as prescribed in the ordinance.
              </li>
              <li>
                The schemes for the organization must be formulated, modified,
                and revised for the faculties/schools/departments and reported
                to the Governing Body for approval.
              </li>
            </ol>

            <div className="row">
              <div className="col-12 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className=" card-header align-items-center table-title">
                    <h5 className="mb-0 text-white font-weight-bold">
                      Member of the Academic Council
                    </h5>
                  </div>
                  <div className="table-responsive ">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name of Member</th>
                          <th>Board Designation</th>
                          <th>Organizational Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Prof. (Dr) Anand Mahalwar </td>
                          <td>Chairperson </td>
                          <td>Hon’ble Vice-Chancellor, ISBM University</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Dr. N. Kumar Swamy</td>
                          <td>Member</td>
                          <td>Dean (In- charge), ISBM University</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Dr. Poonam Verma</td>
                          <td>Member</td>
                          <td>HOD Faculty of Science</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Dr. Garima Diwan</td>
                          <td>Member</td>
                          <td>HOD Faculty of Art & Humanity</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Mr. Rajendra Prasad</td>
                          <td>Member</td>
                          <td>HOD Faculty of Law</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Ms. Kesharlata Sahu</td>
                          <td>Member</td>
                          <td>HOD Faculty of Commerce & Management</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Shri Jitendra Nahar</td>
                          <td>Member</td>
                          <td>Nominee, Chancellor, ISBM University</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Shri Munir Sayyad</td>
                          <td>Member</td>
                          <td>
                            Nominee, Chancellor, ISBM University Address:
                            Reliance Corporate Park Cabin No. 01, Ground floor,
                            TC-23, Block-C, Phase-IC, Ghansoli, Navi Mumbai-
                            400701 Maharashtra
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Mr. Bhushan Kumar Sahu</td>
                          <td>Member</td>
                          <td>
                            Asst. Professor Zoology, Govt. College Gariyaband
                            (CG)
                          </td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Mr. P.K. Bhoi</td>
                          <td>Member</td>
                          <td>
                            Principal, Govt. College Chhura, District –
                            Gariyaband (CG)
                          </td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Mr. S.R. Vadde</td>
                          <td>Member</td>
                          <td>
                            Asst. Professor Commerce, Shri Khuleshwar Mahadev
                            Govt. College Gobra Nawapara, Raipur ,C.G.
                          </td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>Mr. Takeshwar Kaushik</td>
                          <td>Member </td>
                          <td>HOD Faculty of Engineering & Technology</td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>Ms. Vimla Sona</td>
                          <td>Member </td>
                          <td>HOD Faculty of Library & Information Science</td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>Dr. Barada Prasad Bhol</td>
                          <td>Member Secretary</td>
                          <td>Registrar, ISBM University</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <a href={mom1} target={"/"}>
              <b className="text-decoration-underline text-darkblue fs-4">
                MOM of Academic Council meeting
              </b>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Academiccouncil;
