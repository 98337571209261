import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import mom from "../../Images/Ordinances andStatute/MOM of GB meeting 25112022.pdf";

import EnquiryNow from "../common/EnquiryNow3";



const Governingbody = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    
      <Topnav />
      <Menubar />
      <div className="comminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Governing Body</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Governing Body</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <p>
              The Governing Body is the principal decision-making body of the
              University. It has the power and responsibility to develop
              strategic plans for the deployment of resources to ensure academic
              excellence. It monitors and evaluates the effectiveness of the
              curriculum and strategies from time to time.
            </p>
            <p>
              The powers and functions of the Governing Body are as follows.
            </p>

            <ol type="1">
              <li>
                To review, suggest, and approve policies, plans, procedures, and
                measures for the University's development from time to time.
              </li>
              <li>
                To make recommendations to the Chancellor/Sponsoring Body
                regarding different strategies.
              </li>
              <li>
                To make recommendations to the Sponsoring Body for the creation
                of new officers, teachers, and staff of the University.
              </li>
              <li>
                To exercise such other powers and functions as may be assigned
                by the Sponsoring Body.
              </li>
              <li>
                To consider and approve the recommendations made by the Board of
                Management, Academic Council, Chancellor, and Vice Chancellor of
                the University.
              </li>
              <li>
                The Governing Body shall meet at least 3 times in a calendar
                year.
              </li>
              <li>
                The Governing Body shall maintain a quorum of 5 members as per
                section 22(5) of the Act.
              </li>
            </ol>

            <div className="row">
              <div className="col-12 mb-3 mb-lg-5">
                <div className="position-relative card table-nowrap table-card">
                  <div className=" card-header align-items-center table-title">
                    <h5 className="mb-0 text-white font-weight-bold">
                      Member of the Governing Body
                    </h5>
                  </div>
                  <div className="table-responsive ">
                    <table className="table mb-0">
                      <thead className="small text-uppercase bg-body text-muted ">
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name of Member</th>
                          <th>Committee Designation</th>
                          <th>Organizational Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Dr. Vinay Agrawal</td>
                          <td>Chairman </td>
                          <td>Chancellor ISBM University</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Prof.(Dr) Anand Mahalwar</td>
                          <td>Member </td>
                          <td>Vice Chancellor ISBM University</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Mrs. Pramila Mahesh Agrawal</td>
                          <td>Member</td>
                          <td>Nominee, Sponsoring Body, ISBM University</td>
                        </tr>
                        <tr>
                          <td>4</td>

                          <td>Shri Mahesh Ratanlal Agrawal</td>
                          <td>Member</td>
                          <td>Nominee, Sponsoring Body, ISBM University</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Shri Yashwanth Raj Parasmal</td>
                          <td>Member</td>
                          <td>Nominee, Sponsoring Body, ISBM University</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Dr. Sarita Sinha</td>
                          <td>Member</td>
                          <td>
                            Principal (Retd.) Govt. Mata Karma Girls College,
                            Mahasamund (CG). Nominee & Representative of State
                            Govt.
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Dr. Madan Lal Lakhanpal</td>
                          <td>Member</td>
                          <td>
                            Principal (Retd.) Govt. Danteshwari Girls
                            Postgraduate College, Jagdalpur (CG). Nominee &
                            Representative of State Govt.
                          </td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Dr. Sheela Tiwari</td>
                          <td>Member</td>
                          <td>
                            Principle (Retd.) Govt. P.G. College, Bilashpur
                            (CG). Nominee & Representative of State Govt.
                          </td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>Dr. Barada Prasad Bhol</td>
                          <td>Member Secretary</td>
                          <td>Registrar, ISBM University</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <a href={mom} target={"/"}>
              <b className="text-decoration-underline text-darkblue fs-4">
                MOM of Governing Body meeting
              </b>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Governingbody;
