import React, { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { useParams, Link } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import Footer from "../common/footer";

function NewsDetails() {
  const baseUrl = "../admin-portal/admin-assets/news-banners/";
  const [news, setNews] = useState([]);
  const { newsId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchNewssDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/news/${newsId}`
        );
        const newsData = response.data.data[0];
        setNews(newsData);
      } catch (error) {
        console.error("Error fetching news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewssDetails();
  }, [newsId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {news && (
        <>
          <Topnav />
          <Menubar />
          <div className="blog-single gray-bg">
            <div className="container">
              <article className="article">
                <div className="article-img">
                  <img
                    src={baseUrl + news.banner}
                    title={news.title}
                    alt={news.title}
                  />
                </div>
                <div className="article-title">
                  <h6>
                    <a href="#">News</a>
                  </h6>
                  <h2>{news.title}</h2>
                </div>
                <div className="article-content">
                  <img src={news.content} title={news.title} alt={news.title} />
                </div>
              </article>
            </div>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
}

export default NewsDetails;
