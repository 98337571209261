import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import vinay from "../../Images/Hon'ble Persons/Dr. Vinay M. Agrawal.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";
const Chancellor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Privacy Policy</h1>
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Row>
          <Col lg={9} className="m-auto">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Privacy Policy</Breadcrumb.Item>
            </Breadcrumb>
            <p className="text-justify">
              At ISBM University, we value the trust you place in us and are
              committed to ensuring the security of your personal information.
              This Privacy Policy outlines how ISBM University collects, uses,
              shares, and processes your personal data through its website
              <a href="https://isbmuniversity.edu.in">
                https://isbmuniversity.edu.in
              </a>
              . By visiting this platform, providing your information, or using
              our services, you expressly consent to the terms and conditions
              outlined in this Privacy Policy, as well as our Terms of Use, and
              agree to be governed by the applicable laws of India, including
              those pertaining to data protection and privacy. If you do not
              agree with any part of this Privacy Policy, please refrain from
              using or accessing our platform.
            </p>
            <h5 class="fw-bold text-decoration-underline">Collection of Your Information</h5>
            <p className="text-justify">
              When you engage with our platform, we may collect and store the
              personal information you provide to us from time to time. This
              information helps us tailor our services and enhance your
              experience. You can explore certain areas of our platform without
              revealing your identity or registering. However, once you
              voluntarily provide your personal details, such as your name,
              contact information, or payment details, you will no longer be
              anonymous to us.
            </p>
            <p>We collect information in the following ways:</p>
            <ul>
              <li>
                <b>Voluntary Submission:</b>
                <br />
                When you register for an account, participate in events, or
                contact us for inquiries, we collect personal information such
                as your name, email address, phone number, postal address, and
                payment details.
              </li>
              <li>
                <b> Automated Data Collection:</b>
                <br />
                We may automatically collect information about your interactions
                with our platform, including your IP address, browsing history,
                device type, browser type, and the URL you visited before and
                after accessing our platform.
              </li>
              <li>
                <b> Third-Party Sources:</b>
                <br />
                Occasionally, we may receive information about you from third
                parties, such as social media platforms or payment processors,
                which may be used in accordance with this Privacy Policy.
              </li>
            </ul>
            <p>
              The information collected is used to enhance your experience,
              personalize your interaction with the platform, improve our
              services, and conduct internal research on user demographics,
              interests, and behavior.
            </p>

            <h5 class="fw-bold text-decoration-underline">Use of Your Information </h5>
            <p>We use your personal information to:</p>
            <ul>
              <li>
                Facilitate the provision of our educational services, including
                course registration, events, and contests.
              </li>
              <li>
                Process your transactions securely, including payment and
                billing information.
              </li>
              <li>
                Respond to your inquiries, feedback, and requests for
                information.
              </li>
              <li>
                Monitor and analyze trends, usage, and activities related to our
                platform to continuously improve our offerings.
              </li>
              <li>
                Enhance the user experience by providing customized content and
                recommendations based on your preferences.
              </li>
              <li>
                Maintain the security of the platform, detect and prevent fraud,
                and ensure compliance with legal obligations.
              </li>
              <li>
                Conduct research and analysis for academic purposes, ensuring
                data anonymity when possible.
              </li>
            </ul>

            <h5 class="fw-bold text-decoration-underline">Cookies and Tracking Technologies </h5>
            <p className="text-justify">
              To improve your experience and streamline our services, ISBM
              University uses cookies and other tracking technologies. Cookies
              are small text files placed on your device to store information
              about your interaction with the platform, enabling us to recognize
              your preferences and deliver tailored content. We use session
              cookies that expire once you close your browser, and persistent
              cookies that remain on your device until deleted.
            </p>
            <p>Cookies serve multiple purposes, including:</p>
            <ul>
              <li>
                <b>Enhancing Usability:</b> By storing your preferences, cookies
                allow you to navigate our platform more efficiently.
              </li>
              <li>
                <b>Improving Security:</b> We use cookies to verify user
                authenticity and protect against fraudulent activity.
              </li>
              <li>
                <b>Analytics and Performance:</b> We utilize third-party tools
                such as Google Analytics to understand how users interact with
                the platform and gather insights that help us improve our
                services.
              </li>
            </ul>
            <p>
              If you wish to control or disable cookies, you can do so through
              your browser settings. However, this may limit your access to
              certain features of the platform.
            </p>

            <h5 class="fw-bold text-decoration-underline">Disclosure of Personal Information</h5>
            <p>
              Your personal information is treated with strict confidentiality
              and will not be disclosed to third parties without your consent,
              except in the following circumstances:
            </p>
            <ul>
              <li>
                <b>Legal Compliance:</b> We may disclose personal data if
                required by law or to respond to legal processes, such as court
                orders or government requests.
              </li>
              <li>
                <b>Business Transactions:</b> In the event of a merger,
                acquisition, or sale of assets, your personal information may be
                transferred to the new entity as part of the transaction.
              </li>
              <li>
                <b>Protection of Rights:</b> We may disclose your data in good
                faith if necessary to protect the rights, property, or safety of
                ISBM University, its users, or the public.
              </li>
              <li>
                <b>Third-Party Service Providers:</b> We may share information
                with trusted third-party vendors who assist us in operating the
                platform, processing payments, or providing other services on
                our behalf. These third parties are bound by confidentiality
                agreements and are prohibited from using your information for
                any other purpose.
              </li>
            </ul>

            <h5 class="fw-bold text-decoration-underline">Data Security</h5>
            <p>
              ISBM University takes the security of your personal information
              seriously. We employ industry-standard encryption technologies,
              secure servers, and robust internal policies to safeguard your
              data from unauthorized access, loss, or disclosure. While we
              strive to protect your information, no data transmission over the
              internet is 100% secure. Therefore, we cannot guarantee absolute
              security, but we are committed to continuously improving our
              security measures.
            </p>
            <h5 class="fw-bold text-decoration-underline">Retention of Your Information</h5>
            <p>
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required by law. When your data is no
              longer needed, it will be securely deleted or anonymized in
              compliance with applicable regulations.
            </p>

            <h5 class="fw-bold text-decoration-underline">Your Rights</h5>
            <p>
              As a user of our platform, you have the following rights
              concerning your personal information:
            </p>
            <ul>
              <li>
                <b>Access and Correction:</b> You can request access to the
                personal information we hold about you and update any
                inaccuracies.
              </li>
              <li>
                <b>Deletion:</b> You have the right to request the deletion of
                your personal information, subject to legal and contractual
                obligations.
              </li>
              <li>
                <b> Withdrawal of Consent:</b> If you have provided consent for
                the collection and processing of your data, you may withdraw
                this consent at any time. However, this may limit your access to
                certain features of the platform.
              </li>
              <li>
                <b>Opt-Out:</b> You may opt out of receiving marketing
                communications from us by following the unsubscribe instructions
                included in each communication.
              </li>
            </ul>
            <p>
              Changes to this Privacy Policy.Please check our Privacy Policy
              periodically for changes. We may update this Privacy Policy to
              reflect changes to our information practices.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Chancellor;
