import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Events() {
  const baseUrl = "https://isbmuniversity.edu.in/admin-portal/admin-assets/event-banners/";

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/eventApi"
        );
        setEvents(response.data.data);
        //console.log(response);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);
  return (
    <React.Fragment>
      <Col className="container py-5">
        <Row>
          <h3 className="col-2 news-events-title">Events</h3>
          <div className="col-10 news-events-title-divider h-0 m-auto"></div>
        </Row>
        <div className="row position-relative mt-n1-9">
          {events.map((event) => (
            <div key={event.id} className="col-lg-4 col-md-6 col-sm-12 p-3">
              <div className="team-style1 text-center">
                <img
                  src={baseUrl + event.banner}
                  className="border-radius-5"
                  alt={event.title}
                  style={{ height: "400px" }}
                ></img>
                <div className="team-info">
                  <h4 className="text-wite mb-1 h4">{event.title}</h4>
                </div>
                <div className="team-overlay">
                  <Link
                    className="text-right font-weight-bold"
                    to={`/event-details/${event.id}`}
                  >
                    <div className="d-table h-100 w-100">
                      <div className="d-table-cell align-middle">
                        <h4 className="text-white mb-1 h4">{event.title}</h4>
                        <ul className="social-icon-style1">
                          <li>
                            <Link
                              className="text-right font-weight-bold"
                              to={`/event-details/${event.id}`}
                            >
                              Read More
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Col>
    </React.Fragment>
  );
}

export default Events;
