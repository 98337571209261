import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Careercounselling = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="Counsellinginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Career Counselling</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Career Counselling</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Career Counselling</h2>
            <p>
              There are hundreds of careers options, multiple stream
              combinations and endless career paths. ISBM University help you
              choose your right path like the thousands of students we have
              helped !
            </p>
            <p>
              Career Counseling is a process that will help you to know and
              understand yourself and the world of work in order to make career,
              educational, and life decisions. Career development is more than
              just deciding on a major and what job you want to get when you
              graduate.
            </p>
            <p>
              Whether you are a student or recent grad or at the midpoint of
              your career trajectory and thinking about a career change, career
              counseling could help you make sound decisions for your
              professional journey.
            </p>
            <p>
              ISBM University helps students discover perfect career through its
              multi dimensional career assessment, and revolutionary approach to
              career counselling and career guidance.{" "}
            </p>
            <h5 className="text-purple fw-bold">Career Counselling</h5>
            <p>
              It is the process of giving advice, support and guidance for
              selecting the right stream after class 10th, and the right career
              after 12th and graduation and during the graduation and post
              graduation also. Career confusion and indecision can affect you at
              any stage of your career, thus it is important to get the right
              support to resolve it and plan ahead. Career Counsellors in ISBM
              University let you explore different career options, their scope,
              colleges and opportunities, and help you make the right career
              choice. With a competent team of career counsellors, you can rest
              assured that you’ll get the most reliable advice and guidance for
              your career.{" "}
            </p>
            <h5 className="text-purple fw-bold">Career Assessments</h5>
            <p>
              A Career Assessment is a test designed to understand where your
              unique set of strengths, aptitudes and skills lie, in order to
              recommend the right careers for you. ISBM University Career
              Assessment team organizes an online career test that evaluates
              students on various parameters across different dimensions
              including Personality, Aptitude, Interest, EQ and Orientation
              Style, to suggest top career options. Not only that, students get
              detailed career development plans and expert guidance within a
              comprehensive career report itself, so candidates can take the
              first step towards achieving their career goals.{" "}
            </p>
            <h5 className="text-purple fw-bold"> Career Guidance</h5>
            <p>
              Career Guidance takes the benefits of career assessments and
              career counselling to the next level. It is the process where a
              career guide helps you on your path to career development and
              career success. Being successful in a career takes extensive
              efforts, and with an experienced coach/mentor, your career
              development efforts can find the right direction. Career
              counselors at ISBM University provide career guidance by building
              customized career action plans and road map, direct students back
              to the right path, provide personalized 24x7 support, and help
              them at every step along the way to a successful career.
            </p>
            <h5 className="text-purple fw-bold">
              {" "}
              Shaping the Career Guidance Landscape
            </h5>
            <p>
              Comprehensive career guidance solutions for students, parents,
              educators and schools.
            </p>
            <p>
              Enable students to identify their best-fit career with our
              world-class career assessment and personalised guidance.
            </p>
            <p>
              Empower students to learn all about the professional world with
              virtual career simulations, exhaustive career library.
            </p>
            <p>
              Pave student’s way to their dream college with our end-to-end
              college application guidance, scholarship drive and corporate
              internship program.
            </p>
            <p>
              Enable schools in creating a career guidance ecosystem in sync
              with the vision of New Education Policy
            </p>
            <p>
              Empower educators to become International Certified Career Coaches
              and build a career in career guidance & counseling
            </p>
            <p>
              Revolutionary assessment platform and technology driven career
              guidance solutions for educators to boost their career guidance &
              counselling practice
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Careercounselling;
