import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Internationalsidebaar from "../sidebars/InternationalSidebar";
import EnquiryNow from "../common/EnquiryNow3";
const ProspectiveInternational = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="internationalinnerbackimage innerbackimage">
      <div className="text_banners_abcds">
          <h1>Prospective International Students</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      
      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Prospective International Students</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Internationalsidebaar />
          <Col lg={9}>
            <h2 className="linkabout123 ">
            Prospective International Students
            </h2>
            <section className="section services-section" id="services">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6 col-lg-4">
                    <div className="feature-box-1">
                        <h4>Unparalleled Opportunities Awaits You!</h4>
                        <p>
                          Discover a world of opportunities at ISBM University
                          with over 100 undergraduate, postgraduate, and
                          doctoral degree programs. Our esteemed faculty
                          members, known for their excellence in teaching and
                          research, will guide you through your academic
                          journey, ensuring you receive a top-tier education
                          that prepares you for success.
                        </p>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <div className="feature-box-1">
                      <div className="feature-content">
                        <h4>Our Global Vision</h4>
                        <p>
                          At ISBM University, our vision extends beyond borders.
                          Through our globally-focused research initiatives and
                          international partnerships, we aim to address global
                          challenges and empower students to become effective
                          global citizens. Step into a world of possibilities
                          and embark on a transformative educational experience
                          that transcends cultural boundaries.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <div className="feature-box-1">
                      <div className="feature-content">
                        <h4>Your Path to Success Starts Here</h4>
                        <p>
                          With our International Partner University Program, we
                          make it seamless for you to expand your academic
                          horizons and gain invaluable international experience.
                          Our aim is to equip you with the skills and knowledge
                          needed to thrive in an interconnected world, preparing
                          you to make a meaningful impact on a global scale.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <div className="feature-box-1">
                      <div className="feature-content">
                        <h4>Embrace Diversity, Explore Excellence</h4>
                        <p>
                          Experience the vibrant international community at ISBM
                          University, where students from around the world come
                          together to learn, grow, and thrive. Our commitment to
                          diversity fosters a welcoming environment where
                          cultural exchange and global perspectives are
                          celebrated.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-4">
                    <div className="feature-box-1">
                      <div className="feature-content">
                        <h4>Join the ISBM University Family Today!</h4>
                        <p>
                          Come be a part of our dynamic community, where
                          innovation meets tradition, and every day brings new
                          opportunities for growth and discovery. Your journey
                          to success begins at ISBM University - where the world
                          is your classroom, and the possibilities are endless!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ProspectiveInternational;
