import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./App.css";
import "./mediaCss.css";
import "./Banner.css";
import "./Style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/home/Home";
import CourseDetails from "./components/academics/CourseDetailsVTwo";
import SchoolDetails from "./components/academics/SchoolDetails";
import Aboutus from "./components/about/AboutUs";
import PrivacyPolicy from "./components/about/PrivacyPolicy";
import Footer from "./components/common/footer";
import Visionmision from "./components/about/Visionmision";
import Visitor from "./components/about/Visitor";
import Patron from "./components/about/Patron";
import Chancellor from "./components/about/Chancellor";
import Directorgeneral from "./components/about/Directorgeneral";
import Vicechancellor from "./components/about/Vicechancellor";
import Registor from "./components/about/Registrar";
import Governingbody from "./components/about/Governingbody";
import Message from "./components/about/Message";
import Boardmanagement from "./components/about/Boardmanagement";
import Academiccouncil from "./components/about/Academiccouncil";
import Boardadvisor from "./components/about/Boardadvisor";
import Other from "./components/about/OtherCommittees";
import Statues from "./components/about/Statues";
import Recognisation from "./components/about/Recognisation";
import Awards from "./components/about/Awards";
import Media from "./components/about/Media";
import Cocfaculty from "./components/about/Cocfaculty";
import Cocmanagement from "./components/about/Cocmanagement";
import Cocstudents from "./components/about/Cocstudents";
// import Enquiry_now from "./components/common/enquiry_now";
import Internationalpartnership from "./components/International/international_partnership";
import Internationalstudent from "./components/International/international_student";
import RushfordInfo from "./components/International/RushfordInfo";
import HenryInfo from "./components/International/HenryInfo";
import Overview from "./components/campus/Overview";
import Library from "./components/campus/Library";
import Laboratory from "./components/campus/Laboratery";
import CampusInfrastructure from "./components/campus/CampusInfrastructure";
import ITLab from "./components/campus/ITLab";
import Extracurricular from "./components/campus/ExtraCurricular";
import Digilearnsys from "./components/campus/DigiLearnSys";
import Busrouteschedule from "./components/campus/BusRouteSchedule";
import Bustransportation from "./components/campus/BusTransportation";
import Hostel from "./components/campus/Hostel";
import Complaints from "./components/campus/Complaints";
import Complaintscast from "./components/campus/ComplaintsCast";
import Aboutplacements from "./components/placement/AboutPlacements";
import Careercounselling from "./components/placement/CareerCounselling";
import Globalskills from "./components/placement/GlobalSkills";
import Recruiters from "./components/placement/Recruiters";
import Campustraining from "./components/placement/CampusTraining";
import Workat from "./components/placement/WorkAt";
import Faqs from "./components/studentservices/Faqs";
import Scholarship from "./components/studentservices/Scolarship";
import Antiragging from "./components/studentservices/AntiRagging";
import Downloads from "./components/studentservices/Downloads";
import Studentgrievance from "./components/studentservices/StudentGrievance";
import Placementregistration from "./components/placement/PlacementRegistration";
import ProgrammesAndEligibilities from "./components/admission/ProgrammesAndEligibilities";
import ProgrammesByQualification from "./components/admission/ProgrammesByQualification";
import Admissionhelpdesk from "./components/admission/AdmissionHelpdesk";
import Admissionprocedure from "./components/admission/AdmissionProcedure";
import Paymentprocedure from "./components/admission/PaymentProcedure";
import Admissiondownload from "./components/admission/AdmissionDownload";
import Skilldevelopment from "./components/admission/SkillDevelopment";
import Applyonline from "./components/admission/ApplyOnline";
import Contactus from "./components/topnav/ContactUs";
import Onlinepayment from "./components/admission/OnlinePayment";
import Isbm_pro from "./components/topnav/IsbmuPro";
import IndustryIntegratedProgrammes from "./components/admission/IndustryIntegratedProgrammes";
import After10th from "./components/quicklinks/After10th";
import AcademicPhilosophy from "./components/academics/AcademicPhilosophy";
import AcademicPartners from "./components/academics/AcademicPartners";
import SpecialDevelopmentSkill from "./components/academics/SpecialDevelopmentCell";
import OnlineResources from "./components/academics/OnlineResources";
import TeachingMethodology from "./components/academics/TeachingMethodology";
import MOOC from "./components/academics/MOOC";
import MOUs from "./components/academics/MOUs";
import CertProgramMDP from "./components/academics/CertProgramMDP";
import Research from "./components/admission/Research";
import ProspectiveInternational from "./components/International/ProspectiveInternational";
import ProgrammedDetails from "./components/common/ProgrammedDetails";
import AdmissionForm from "./components/common/AdmissionFormModal";
import EventDetail from "./components/home/EventDetail";
import NewsDetail from "./components/home/NewsDetail";
import RTI from "./components/about/RTI";
import Applyonline2 from "./components/admission/ApplyOnline2";
import CocHostel from "./components/about/CocHostel";
import NotFoundPage from "./404Page";
import ErrorBoundary from "./components/academics/ErrorBoundary";
import LP from "./components/LP/LP";
import AntiRaggingCommittees from "./components/studentservices/AntiRaggingCommittees";
import AntiRaggingSquad from "./components/studentservices/AntiRaggingSquad";
import SCandSTCommittee from "./components/studentservices/SCandSTCommittee";
import OBCCommittee from "./components/studentservices/OBCCommittee";
import StudentGrievanceCommittees from "./components/studentservices/StudentGrievanceCommittees";
import ICCCommittee from "./components/studentservices/ICCCommittee";
import CulturalCommittee from "./components/studentservices/CulturalCommittees";
import StudentCouncil from "./components/studentservices/StudentCouncil";

const App = () => {
  useEffect(() => {
    const rootDiv = document.getElementById("root");
    if (!rootDiv || rootDiv.innerHTML.trim() === "") {
      // Redirect to 404 page if the root div is empty
      window.location.replace("/404");
    }
  }, []);
  return (
    <Router>
      <ErrorBoundary>
        <Routes>
          <Route path="/" element={<LP />} />
          <Route path="/1" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/404" Component={NotFoundPage} />

          {/* About us Sec */}
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/vision-mission" element={<Visionmision />} />
          <Route path="/honorable-visitor" element={<Visitor />} />
          <Route path="/honorable-patron" element={<Patron />} />
          <Route path="/honorable-chancellor" element={<Chancellor />} />
          <Route path="/directorgeneral" element={<Directorgeneral />} />
          <Route
            path="/honorable-vice-chancellor"
            element={<Vicechancellor />}
          />
          <Route path="/official-registar" element={<Registor />} />
          <Route path="/governing-body" element={<Governingbody />} />
          <Route path="/message" element={<Message />} />
          <Route path="/board-management" element={<Boardmanagement />} />
          <Route path="/academic-council" element={<Academiccouncil />} />
          <Route path="/board-advisor" element={<Boardadvisor />} />
          <Route path="/other-communitie" element={<Other />} />
          <Route path="/statues-and-ordinance" element={<Statues />} />
          <Route path="/recognisation" element={<Recognisation />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/media-coverage" element={<Media />} />
          <Route path="/RTI" element={<RTI />} />
          <Route path="/code-of-conduct-faculty" element={<Cocfaculty />} />
          <Route
            path="/code-of-conduct-management"
            element={<Cocmanagement />}
          />
          <Route path="/code-of-conduct-student" element={<Cocstudents />} />
          <Route
            path="/code-of-conduct-boardes-of-hostel"
            element={<CocHostel />}
          />
          {/* International */}
          <Route
            path="/prospective-international-students"
            element={<ProspectiveInternational />}
          />
          <Route
            path="/international-partnerships"
            element={<Internationalpartnership />}
          />
          <Route
            path="/international-students"
            element={<Internationalstudent />}
          />
          <Route path="/rushford-business-school" element={<RushfordInfo />} />
          <Route path="/henry-harvin" element={<HenryInfo />} />
          <Route
            path="/course-details/:courseName"
            element={<CourseDetails />}
          />
          <Route
            path="/school-details/:schoolName"
            element={<SchoolDetails />}
          />
          
          <Route path="/event-details/:eventId" element={<EventDetail />} />
          <Route path="/news-details/:newsId" element={<NewsDetail />} />
          {/* campus */}
          <Route path="/overview" element={<Overview />} />
          <Route path="/library" element={<Library />} />
          <Route path="/laboratory" element={<Laboratory />} />
          <Route
            path="/campus-infrastructure"
            element={<CampusInfrastructure />}
          />
          <Route path="/information-technology-lab" element={<ITLab />} />
          <Route path="/extra-curricular" element={<Extracurricular />} />
          <Route path="/digitized-learning-system" element={<Digilearnsys />} />
          <Route
            path="/bus-routes-and-schedules"
            element={<Busrouteschedule />}
          />
          <Route
            path="/bus-transportation-fees"
            element={<Bustransportation />}
          />
          <Route path="/hostel" element={<Hostel />} />
          <Route path="/complaints" element={<Complaints />} />
          <Route
            path="/complaint-against-caste-based-discrimination"
            element={<Complaintscast />}
          />
          {/* placement */}
          <Route path="/about-placement" element={<Aboutplacements />} />
          <Route path="/career-counselling" element={<Careercounselling />} />
          <Route path="/global-skills" element={<Globalskills />} />
          <Route path="/recruiters" element={<Recruiters />} />
          <Route
            path="/campus-recruitment-interview-training"
            element={<Campustraining />}
          />
          <Route path="/work-at-isbmu" element={<Workat />} />
          <Route path="/placement-form" element={<Placementregistration />} />
          {/* Student Services */}
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/anti-ragging-policy" element={<Antiragging />} />
          <Route path="/anti-ragging-committee" element={< AntiRaggingCommittees />} />
          <Route path="/anti-ragging-squad" element={< AntiRaggingSquad />} />
          <Route path="/sc-and-st-committee" element={< SCandSTCommittee />} />
          <Route path="/obc-committee" element={< OBCCommittee />} />
          <Route path="/student-grievance-committee" element={< StudentGrievanceCommittees />} />
          <Route path="/icc-committee" element={< ICCCommittee />} />
          <Route path="/cultural-committee" element={< CulturalCommittee />} />
          <Route path="/student-council" element={< StudentCouncil />} />
          

          <Route path="/download-form" element={<Downloads />} />
          {/* <Route path="/student-grievance" element={<Studentgrievance />} /> */}
          {/* admissions */}
          <Route
            path="/programmes-and-eligibilities"
            element={<ProgrammesAndEligibilities />}
          />
          <Route
            path="/programmes-by-qualification"
            element={<ProgrammesByQualification />}
          />
          {/* <Route
            path="/industry-integrated-programmes"
            element={<IndustryIntegratedProgrammes />}
          /> */}
          <Route path="/admission-help-desk" element={<Admissionhelpdesk />} />
          <Route
            path="/admission-procedure-guidelines"
            element={<Admissionprocedure />}
          />
          <Route
            path="/payment-procedure-guidelines"
            element={<Paymentprocedure />}
          />
          <Route path="/downloads" element={<Admissiondownload />} />
          <Route path="/pay-fees-online" element={<Onlinepayment />} />
          <Route path="/skill-development" element={<Skilldevelopment />} />
          <Route path="/apply-online" element={<Applyonline />} />
          <Route path="/Research" element={<Research />} />
          {/* <Route path="/apply-online-new" element={<Applyonline2 />} /> */}

          {/* topnav */}
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/isbm-pro" element={<Isbm_pro />} />
          {/* quicklinks */}
          <Route path="/after-10th-programmes" element={<After10th />} />
          {/* academics */}
          <Route path="/academic-philosophy" element={<AcademicPhilosophy />} />
          <Route path="/academic-partner" element={<AcademicPartners />} />
          {/* <Route
            path="/special-development-cells"
            element={<SpecialDevelopmentSkill />}
          /> */}
          <Route path="/online-resources" element={<OnlineResources />} />
          <Route
            path="/teaching-methodology-pedagogy"
            element={<TeachingMethodology />}
          />
          <Route path="/mooc-with-isbm" element={<MOOC />} />
          <Route path="/mous-and-collaborations" element={<MOUs />} />
          {/* <Route
            path="/certificate-programmes-and-mdps-certificate-courses"
            element={<CertProgramMDP />}
          /> */}
          {/* programme details */}
          <Route path="/programmes" element={<ProgrammedDetails />} />
          <Route path="/admission-form" element={<AdmissionForm />} />
        </Routes>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
