import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";
import scholar1 from "../../Images/commonimg/scholar1.webp";
import scholar2 from "../../Images/commonimg/scholar2.webp";
import scholar3 from "../../Images/commonimg/scholar3.webp";
import scholar4 from "../../Images/commonimg/scholar4.webp";

import EnquiryNow from "../common/EnquiryNow3";

const Scholarship = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="scholarshipinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Scholarship</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Scholarship</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Scholarship</h2>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mb-4">
                <Accordion.Header>
                  ISBM University is a proud sponsor of “Shushiksha"
                  Chhattisgarh
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4} className="mb-4 award-img">
                      <img
                        src={scholar1}
                        alt="Sponsor of Shushiksha
                        Chhattisgarh"
                      ></img>
                    </Col>
                    <Col lg={8} className="award-para">
                      <p>
                        ISBM University is a proud sponsor of “Shushiksha"
                        Chhattisgarh an Education Conclave 2018. Education
                        Conclave was an effort to bring together Policy Makers,
                        Institution Heads, Decision Makers, Industry HR
                        Officials and Edu-tech Players under one roof to explore
                        the various space in the Education field..
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mb-4">
                <Accordion.Header>
                  ISBM University has been fortunate to host several scholarship
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4} className=" award-img">
                      <img src={scholar2} alt="ISBM university scholarship"></img>
                    </Col>
                    <Col lg={8} className="fs-7">
                      <p>
                        ISBM University has been fortunate to host several
                        scholarship awarding events for the students These
                        events have been graced by the supremos of the state Dr.
                        Raman Singh (Former Chief Minister of Chhattisgarh) and
                        Shri Bhupesh Baghel (Chief Minister of Chhattisgarh) who
                        have awarded scholarship certificates to the deserving
                        students of ISBM University respectively.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mb-4">
                <Accordion.Header>
                  We offer and array of scholarships for the deserving students
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4} className="award-img">
                      <img src={scholar3} alt="ISBM university scholarship"></img>
                    </Col>
                    <Col lg={8} className="fs-7">
                      <p>
                        We offer and array of scholarships for the deserving
                        students. It has been a constant effort of I5BM to work
                        towards the upliftment of underprivileged population of
                        the region.We also believe in positive discriminatory
                        treatment of girls and women to educate them in the
                        realm of higher education. ISBM is providing various
                        scholarships for students enrolling in various academic
                        programmes: more information on the same can be obtained
                        from :{" "}
                        <a href="#" className="text-dark fs-7">
                          www.isbmuniversity.edu.in
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mb-4">
                <Accordion.Header>
                  A number of Scholarships are offered to students out of the
                  funds
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col lg={4} className="award-img">
                      <img src={scholar4} alt="ISBM university scholarship"></img>
                    </Col>
                    <Col lg={8} className="fs-7">
                      <p>
                        A number of Scholarships are offered to students out of
                        the funds received in the form of gifts from the
                        University benefactors, depending on the academic. The
                        University grants full to partial waiver on tuition fees
                        payable by the student we extend scholarship to
                        meritorious students based on their academic
                        achievements, caste, gender in accordance with
                        Government norms and ISBM University's policy.Money is
                        not a constraint to pursue higher education, ISBM
                        University also aids the aspirants with education loans
                        to pursue their dreams. So, now it is the time to fly
                        high.
                      </p>
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Scholarship;
