import React, { useEffect, useState } from "react";
import $ from "jquery";

function LPEnquireForm() {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [levels, setLevels] = useState([]);
  const [courses, setCourses] = useState([]);
  
  useEffect(() => {
    // Fetch States
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_states.php",
      dataType: "json",
      success: function (response) {
        if (response && response.states && Array.isArray(response.states)) {
          setStates(response.states);
        } else {
          console.error("Invalid state data format:", response);
        }
      },
      error: function (error) {
        console.error("Error fetching states:", error);
      },
    });

    // Fetch Levels
    const entity = "ISBMU";
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_levels.php",
      data: { entity },
      dataType: "json",
      success: function (response) {
        if (response && response.levels && Array.isArray(response.levels)) {
          setLevels(response.levels);
        } else {
          console.error("Invalid level data format:", response);
        }
      },
      error: function (error) {
        console.error("Error fetching levels:", error);
      },
    });
  }, []);

  const fetchCities = (state) => {
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_cities.php",
      data: { state },
      dataType: "json",
      success: function (response) {
        if (response && response.cities && Array.isArray(response.cities)) {
          setCities(response.cities);
        } else {
          console.error("Invalid city data format:", response);
        }
      },
      error: function (error) {
        console.error("Error fetching cities:", error);
      },
    });
  };

  const fetchCourses = (level) => {
    const entity = "ISBMU";
    $.ajax({
      type: "POST",
      url: "https://insityapp.com/dbFiles/fetch_courses.php",
      data: { level, entity },
      dataType: "json",
      success: function (response) {
        if (response && response.courses && Array.isArray(response.courses)) {
          setCourses(response.courses);
        } else {
          console.error("Invalid course data format:", response);
        }
      },
      error: function (error) {
        console.error("Error fetching courses:", error);
      },
    });
  };

  return (
    <div className="enquireForm gradient-border col-xl-3 col-lg-4 col-md-6 col-sm-12 p-0">
      <h5 className="">Enquire Now</h5>
      <form
        action="https://isbmuniversity.edu.in/submitLead.php"
        method="post"
        className="p-4"
      >
         <div className="form-group">
          <input
            type="text"
            className="form-control mt-4 mb-3"
            id="name"
            name="name"
            placeholder="Enter your Name"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control mb-3"
            id="email"
            name="email"
            placeholder="Enter your Email"
            required
          />
        </div>
        <div className="form-group mb-3" style={{ display: "flex" }}>
          <input
            type="number"
            maxLength="10"
            style={{ width: "100%" }}
            id="mobile_code"
            className="form-control"
            placeholder="Phone Number"
            name="phone"
            autoComplete="off"
            required
          />
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="state"
            name="state"
            required
            onChange={(e) => fetchCities(e.target.value)}
          >
            <option value="">Select State</option>
            {states.map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="city"
            name="city"
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="level"
            name="level"
            required
            onChange={(e) => fetchCourses(e.target.value)}
          >
            <option value="">Select Level</option>
            {levels.map((level) => (
              <option key={level} value={level}>{level}</option>
            ))}
          </select>
        </div>
        <div className="form-group mb-3">
          <select
            className="form-select"
            id="course"
            name="course"
            required
          >
            <option value="">Select Course</option>
            {courses.map((course) => (
              <option key={course} value={course}>{course}</option>
            ))}
          </select>
        </div>
        <input
          type="text"
          className="form-control"
          id="current_url"
          name="current_url"
          value={`${window.location.href}`}
          hidden
        />
        <input
          type="text"
          name="utm_source"
          value={
            new URLSearchParams(window.location.search).get("utm_source") || ""
          }
          hidden
        />
        <input
          type="text"
          name="utm_medium"
          value={
            new URLSearchParams(window.location.search).get("utm_medium") || ""
          }
          hidden
        />
        <input
          type="text"
          name="utm_campaign"
          value={
            new URLSearchParams(window.location.search).get("utm_campaign") ||
            ""
          }
          hidden
        />
        <div className="form-group d-flex justify-content-center">
          <input
            type="submit"
            name="submit"
            value="Submit"
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
}

export default LPEnquireForm;
