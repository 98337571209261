import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const AcademicSidebaar = () => {
  const location = useLocation();

  const menuItems = [
    {
      path: "/academic-philosophy",
      label: " Academic Philosophy",
    },
    {
      path: "/academic-partner",
      label: "Academic Partners",
    },
    // {
    //   path: "/special-development-cells",
    //   label: " Special Development Cells",
    // },
    { path: "/online-resources", label: "Online Resources" },
    { path: "/teaching-methodology-pedagogy", label: "Teaching Methodology/Pedagogy" },
    { path: "/mooc-with-isbm", label: " MOOC with ISBM University" },
    { path: "/mous-and-collaborations", label: "MOUs' and Collaborations" },
    // {
    //   path: "/certificate-programmes-and-mdps-certificate-courses",
    //   label: "Certificate Programmes and MDPs",
    // },
    {
      path: "http://isbmuniversity.online/",
      label: " Student ERP",
    },
  ];

  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default AcademicSidebaar;
