import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const Servicesslidebaar = () => {
  const location = useLocation();

  const menuItems = [
    { path: "/faqs", label: "FAQ" },
    { path: "/scholarship", label: "Scholarship" },
    { path: "/anti-ragging-policy", label: "Anti-Ragging Policy" },
    { path: "/download-form", label: "Download" },
    // { path: "/student-grievance", label: "Student Grievance" },
    { path: "/anti-ragging-committee", label: "Anti Ragging Committee" },
    { path: "/anti-ragging-squad", label: "Anti-Ragging Squad" },
    { path: "/sc-and-st-committee", label: "Scheduled Caste and Scheduled Tribe Committee" },
    { path: "/obc-committee", label: "Other Backward Class Committee" },
    { path: "/student-grievance-committee", label: "Students' Grievence Redressal Committee" },
    { path: "/icc-committee", label: "Internal Complaints Committee" },
    { path: "/cultural-committee", label: "Cultural Committee" },
    { path: "/student-council", label: "Student Council" }
  ];
  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Servicesslidebaar;
