import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Extracurricular = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="extracurriculaminnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Extra Curricular</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Extra Curricular</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Extra Curricular</h2>
            <p>
              ISBM University emphasizes the importance of extracurricular
              activities in fostering overall student development. The
              University offers a wide range of activities and clubs, including:
            </p>
            <ul>
              <li>
                <strong className="text-blue">1. Cultural Clubs:</strong>
                <span>
                  {" "}
                  Various clubs celebrate diversity through dance, music, drama,
                  and art, allowing students to showcase their talents and
                  participate in cultural events.
                </span>
              </li>
              <li>
                <strong className="text-blue">2. Sports Teams:</strong>
                <span>
                  {" "}
                  The University encourages participation in various sports,
                  promoting teamwork and physical fitness through competitions
                  and tournaments.
                </span>
              </li>
              <li>
                <strong className="text-blue">3. Literary Activities:</strong>
                <span>
                  {" "}
                  Students can engage in literary clubs that focus on writing,
                  debating, and public speaking, enhancing their communication
                  skills and confidence.
                </span>
              </li>
              <li>
                <strong className="text-blue">4. Technical Clubs:</strong>
                <span>
                  {" "}
                  These clubs allow students to explore interests in technology
                  and engineering through workshops, competitions, and projects,
                  fostering innovation and skill development.
                </span>
              </li>
              <li>
                <strong className="text-blue">
                  5. Social Service Initiatives:
                </strong>
                <span>
                  {" "}
                  Opportunities for community service and social outreach
                  programs enable students to contribute positively to society
                  while developing empathy and leadership skills.
                </span>
              </li>
              <li>
                <strong className="text-blue">
                  6. Entrepreneurship Development:
                </strong>
                <span>
                  {" "}
                  Programs and clubs focused on entrepreneurship help students
                  cultivate business ideas, network with industry professionals,
                  and participate in startup competitions.
                </span>
              </li>
              <li>
                <strong className="text-blue">
                  7. Workshops and Seminars:
                </strong>
                <span>
                  {" "}
                  Regular workshops and seminars featuring guest speakers from
                  various fields provide students with insights and knowledge
                  beyond their academic curriculum.
                </span>
              </li>
              <li>
                <strong className="text-blue">8. Festivals and Events:</strong>
                <span>
                  {" "}
                  The University hosts various festivals, competitions, and
                  annual events that encourage student participation,
                  collaboration, and celebration of achievements.
                </span>
              </li>
            </ul>
            <p>
              These extracurricular activities at ISBM University contribute to
              a vibrant campus life, helping students develop interpersonal
              skills, leadership qualities, and a sense of community while
              balancing their academic pursuits.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Extracurricular;
