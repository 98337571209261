import React from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const Campusslidebaar = () => {
  const location = useLocation();

  const menuItems = [
    { path: "/overview", label: "Overview" },
    { path: "/library", label: "Library" },
    { path: "/laboratory", label: "Laboratory" },
    { path: "/campus-infrastructure", label: "Campus Infrastructure" },
    // {
    //   path: "/information-technology-lab",
    //   label: "Information Technology Lab",
    // },
    { path: "/extra-curricular", label: "Extra Curricular" },
    { path: "/digitized-learning-system", label: "Digitized Learning System" },
    { path: "/bus-routes-and-schedules", label: "Bus Routes And Schedules" },
    { path: "/bus-transportation-fees", label: "Bus Transportation Fees" },
    { path: "/hostel", label: "Hostel" },
    // { path: "/complaints", label: "Complaints" },
    // {
    //   path: "/complaint-against-caste-based-discrimination",
    //   label: "Complaint Against Caste-Based Discrimination",
    // },
  ];
  return (
    <Col lg={3} className="mobile_view_none11 col-lg-3">
      <h3 className="title">Navigation</h3>
      <ul className="abcd1111">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={location.pathname === item.path ? "active" : ""}
          >
            <Link to={item.path}>{item.label}</Link>
          </li>
        ))}
      </ul>
    </Col>
  );
};

export default Campusslidebaar;
