import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import AcademicSidebaar from "../sidebars/AcademicSidebar";
import { MetaTags } from "react-meta-tags";
import EnquiryNow from "../common/EnquiryNow3";

const AcademicPhilosophy = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>
          ISBM University Academic Philosophy - Shaping Future Leaders
        </title>
        <meta name="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development." />
        <link rel="canonical" href="https://isbmuniversity.edu.in/" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University Academic Philosophy - Shaping Future Leaders"
        />
        <meta
          property="og:description"
          content="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development."
        />
        <meta property="og:url" content="https://isbmuniversity.edu.in/" />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University Academic Philosophy - Shaping Future Leaders"
        />
        <meta
          name="twitter:description"
          content="Nurturing future thought leaders at ISBM University's academic philosophy, focusing on personal connection, intellectual challenge, and leadership development."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Academic Philosophy, Education Excellence, ISBM University Values"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="academecinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Academic Philosophy</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Academic Philosophy</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <AcademicSidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Academic Philosophy</h2>
            <p className="fs-5 fst-italic text-purple">
              ISBM University aims to help students realize dreams through an
              educational pedagogy which focuses better on connecting with them
              as people, inside and outside the classroom.
            </p>
            <p className="detail_text">
              With many experiences enriched over past quite many years, our
              tools of teaching have changed considerably, but the heart of what
              we do has just remained constant. The relationships that our
              faculty members look forward to build with the learners take ISBM
              University education far beyond merely imparting knowledge and
              preparing job-seekers. Our faculties surely experts in their
              respective fields, but seek to produce graduates who will aspire
              to have more than a successful career. According to Chancellor Dr.
              VinayAgrawal, ISBM University's students can make a difference in
              the University itself much before they go out making difference in
              the real world.
            </p>
            <p className="detail_text">
              The academic philosophy of ISBM University is rooted in a
              commitment to excellence, innovation, and holistic development.
              This philosophy guides the university's approach to education,
              research, and community engagement, emphasizing the following key
              principles:
            </p>
            < ol type="1"className="text-justify">
              <li>
                <strong className="text-blue">
                  Excellence in Education:
                </strong>{" "}
                ISBM University strives to provide a high-quality education that
                meets international standards. This involves a rigorous
                curriculum, experienced faculty, and a focus on critical
                thinking, problem-solving, and creativity.
              </li>
              <li>
                <strong className="text-blue">
                  Student-Centred Learning:
                </strong>{" "}
                The university prioritizes the needs and potential of each
                student, fostering an environment that encourages active
                learning, personal growth, and academic achievement.
                Personalized support and mentorship are integral to this
                approach.
              </li>
              <li>
                <strong className="text-blue">
                  Interdisciplinary Collaboration:
                </strong>{" "}
                Recognizing the interconnectedness of knowledge, ISBM University
                promotes interdisciplinary collaboration across different fields
                of study. This encourages students and faculty to explore
                diverse perspectives and develop comprehensive solutions to
                complex issues.
              </li>
              <li>
                <strong className="text-blue">
                  Research and Innovation:
                </strong>{" "}
                The university is dedicated to advancing knowledge through
                cutting-edge research and innovation. Faculty and students are
                encouraged to engage in research projects that address
                real-world challenges and contribute to societal advancement.
              </li>
              <li>
                <strong className="text-blue">
                  Ethical and Social Responsibility:
                </strong>{" "}
                ISBM University instils strong ethical values and a sense of
                social responsibility in its students. This includes a
                commitment to sustainability, equity, and community service,
                preparing graduates to make a positive impact on society.
              </li>
              <li>
                <strong className="text-blue">Global Perspective:</strong>{" "}
                With a focus on global competence, the university prepares
                students to thrive in an interconnected world. This involves
                incorporating international perspectives into the curriculum and
                fostering global partnerships and exchanges.
              </li>
              <li>
                <strong className="text-blue">Lifelong Learning:</strong>{" "}
                ISBM University promotes a culture of lifelong learning,
                encouraging continuous personal and professional development.
                This philosophy supports graduates in adapting to the evolving
                demands of their careers and contributing to their fields
                throughout their lives.
              </li>
            </ol>
            <p className="detail_text">
              By adhering to these principles, ISBM University aims to cultivate
              knowledgeable, skilled, and socially responsible individuals who
              are equipped to contribute meaningfully to their professions and
              communities.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AcademicPhilosophy;
