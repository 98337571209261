import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  Accordion,
  Tab,
  Tabs,
} from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Paymentprocedure = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modalShow1, setModalShow1] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="complaintsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Payment Procedure Guidelines</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>   
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Payment Procedure Guidelines</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Payment Procedure Guidelines</h2>
            <p>
              ISBM University has multiple options available for efficient and
              secured fee payment for students. Details regarding the different
              modes of fee payment for students are given below.
            </p>

            <Tabs
              defaultActiveKey="home"
              id="noanim-tab-example"
              className="payment-procedure"
            >
              <Tab eventKey="home" title="Fees Payment Mode" className="payment-border"   >
                <Row >
              
                  <p>Fees can be paid in either of the following modes:</p>
                  
                  <Col lg={6} >
                    <ul className="inner">
                      <li>Cash</li>
                      <li>Cheque/Demand Draft</li>
                      <li>Credit Card</li>
                    </ul>
                  </Col>
                  <Col lg={6} >
                    <ul className="inner">
                      <li>Online Bank Transfer</li>
                      <li>Online Credit Card Transfer</li>
                      <li>
                        <Link
                          to="/pay-fees-online"
                          style={{ color: "#0961ab" }}
                        >
                          <b>Click here</b>
                        </Link>
                        to make payment
                      </li>
                    </ul>
                  </Col>
                 
                  <p>
                    Please get in touch at admissions@isbmuniversity.edu.in for
                    further details
                  </p>
              
                </Row>
              </Tab>
              <Tab
                eventKey="profile"
                title="Other Related Important Information"
                className="payment-border"
              >
                <Row  >
                  <Col lg={6}>
                    <ul className="inner ">
                      <li >You can opt for more than one course at a time.</li>
                      <li>Fees are subject to revision at any time.</li>

                      <li>
                        Course once selected will not be changed under any
                        circumstance.
                      </li>
                    </ul>
                  </Col>
                  <Col lg={6}>
                    <ul className="inner">
                      <li>
                        You can change the course while taking admission or the
                        same day of admission till the working hours.
                      </li>

                      <li>
                        Full fees has to be paid at the time of admission.
                      </li>

                      <li>
                        Fees once paid is not refundable under any circumstance.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Paymentprocedure;
