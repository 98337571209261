import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb, Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Servicesslidebaar from "../sidebars/ServicesSidebar";

import EnquiryNow from "../common/EnquiryNow3";
 

function Studentgrievance() {
  const [modalShow, setModalShow] = React.useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    enrollmentNumber: "",
    course: "",
    year: "",
    grievance: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendFormDataToServer(formData);
  };

  const sendFormDataToServer = (formData) => {
    fetch("https://isbmuniversity.edu.in/newsEvents/grievance.php", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setFormSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="antiragginginnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Student Grievance</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Student Grievance</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Servicesslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Student Grievance</h2>
            {formSubmitted ? (
              <div className="dialogue-box">
                <h3>Form Submitted Successfully</h3>
                <p>Your grievance has been recorded. Thank you!</p>
              </div>
            ) : (
              <form className="p-3 col-lg-10 col-md-12" onSubmit={handleSubmit}>
                <Row>
                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="name">
                      Name:
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="email">
                      Email:
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="phone">
                      Phone:
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 mb-4">
                    <label
                      className="mb-2 formLabel"
                      htmlFor="enrollmentNumber"
                    >
                      Enrollment Number:
                    </label>
                    <input
                      type="text"
                      id="enrollmentNumber"
                      name="enrollmentNumber"
                      className="form-control"
                      value={formData.enrollmentNumber}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="course">
                      Course:
                    </label>
                    <input
                      type="text"
                      id="course"
                      name="course"
                      className="form-control"
                      value={formData.course}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="year">
                      Year:
                    </label>
                    <input
                      type="text"
                      id="year"
                      name="year"
                      className="form-control"
                      value={formData.year}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-md-12 mb-4">
                    <label className="mb-2 formLabel" htmlFor="grievance">
                      Grievance:
                    </label>
                    <textarea
                      id="grievance"
                      name="grievance"
                      className="form-control"
                      value={formData.grievance}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <button type="submit" className="btn btn-primary mb-2">
                      Submit
                    </button>
                  </div>
                </Row>
              </form>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default Studentgrievance;
