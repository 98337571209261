import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Placementslidebaar from "../sidebars/PlacementSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const Globalskills = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="globalinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Global Skills</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Global Skills</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Placementslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Global Skills</h2>
            <p>
              In today’s interconnected world, the demand for professionals who
              possess global skills is more important than ever. At ISBM
              University, we are dedicated to equipping our students with the
              international competencies and cross-cultural understanding
              necessary to thrive in a globalized job market. Our global skills
              development initiatives are designed to foster a comprehensive
              understanding of diverse cultures, business practices, and global
              trends.
            </p>
            <h5 className="text-purple fw-bold">
              Emphasis on International Competencies{" "}
            </h5>
            <p>
              Our curriculum is structured to include various elements that
              promote international competencies, such as:Our curriculum is
              structured to include various elements that promote international
              competencies, such as:
            </p>
            <p>
              <strong className="text-blue">Global Business Practices:</strong>
              About global business, international trade and economic policies,
              we equip the students with the necessary understanding to prepare
              them for the world market. Knowledge of how enterprises operate
              increases students’ performance depending on the conditions of the
              markets and culture.
            </p>
            <p>
              <strong className="text-blue">
                Cross-Cultural Communication:
              </strong>
              Cultural communication is an important factor in understanding in
              the current diversified employee population. We have specialized
              courses in training that deals with the student’s interpersonal
              communication skills in other to be able to familiar with the
              different culturally diverse clients encountered in clinical
              setting.
            </p>
            <h5 className="text-purple fw-bold">Study Abroad Programs</h5>
            <p>
              The opportunities that ISBM University has for allowing the
              students to study abroad help the students to learn other cultures
              and methodologies. These programs provide valuable international
              exposure and experiences, enabling students to:These programs
              provide valuable international exposure and experiences, enabling
              students to:
            </p>
            <p>
              <strong className="text-blue">
                Experience Diverse Cultures:
              </strong>{" "}
              Studying and living in another country plays an instrumental role
              when it comes to learning new ways of learning to survive and
              engage various cultures, which is very key when it comes to taking
              up a job in the contemporary world.
            </p>
            <p>
              <strong className="text-blue">Network Internationally:</strong>{" "}
              Such programs enable students to interact with fellow students and
              practitioners from other parts of the globe hence enabling
              students to establish good networks to refer to in their future
              working places.
            </p>
            <p>
              <strong className="text-blue">
                Collaborate on Global Projects:
              </strong>{" "}
              Almost all universities offer group projects with international
              students; this means that our students are able to practice what
              we taught them on a real life case scenario and also they get to
              learn how to work in groups with people from other parts of the
              world.
            </p>
            <h5 className="text-purple fw-bold"> Global Industry Partnerships</h5>
            <p>
              We have established partnerships with various international
              organizations and companies that enhance our students' learning
              experiences. These partnerships provide:
            </p>
            <p>
              <strong className="text-blue">
                Guest Lectures and Workshops:
              </strong>{" "}
              Prominent industry leaders from all over the world are invited to
              deliver workshops and seminars to share some unfamiliar concepts
              and key issues of the global industry to the students.
            </p>
            <p>
              <strong className="text-blue">Internship Opportunities:</strong>{" "}
              Bringing relations with industries all over the world enables us
              to place students for internships within some of these proprietary
              worldwide, allowing students an understanding of business within
              an international context.
            </p>
            <p>
              <strong className="text-blue">Research Collaborations:</strong>{" "}
              Such arrangements afford the students possibilities for
              participating in research with institutions in other countries
              and, consequently, to become accustomed to different approaches
              and methods of research
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Globalskills;
