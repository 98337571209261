import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import itimg from "../../Images/commonimg/it-lab1.webp";
import EnquiryNow from "../common/EnquiryNow3";
 

const ITLab = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="overviewinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Information Technology Lab</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Information Technology Lab</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Information Technology Lab</h2>
            <img
              src={itimg}
              alt="best mca colleges in india"
              className="mb-4"
            ></img>
            <p>
              ISBM University recognizes the key role of IT played in teaching
              as well as research. Therefore, it is committed to provide
              computing facilities of the highest standards.
            </p>
            <p>
              State of the Art IT enabled Classrooms, Library and Laboratories.
            </p>

            <p>
              For a student conscious of his future, the campus allows all
              avenues to explore the technology solutions that the University
              offers.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default ITLab;
