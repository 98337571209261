import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import Footer from "../common/footer";

function EventDetails() {
  const baseUrl = "../admin-portal/admin-assets/event-banners/";
  const [event, setEvent] = useState([]);
  const { eventId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchEventsDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/event/${eventId}`
        );
        const eventData = response.data.data[0];
        setEvent(eventData);
      } catch (error) {
        console.error("Error fetching event:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventsDetails();
  }, [eventId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {event && (
        <>
          <Topnav />
          <Menubar />
          <div className="blog-single gray-bg">
            <div className="container">
              <article className="article">
                <div className="article-img">
                  <img
                    src={baseUrl + event.banner}
                    title={event.title}
                    alt={event.title}
                  />
                </div>
                <div className="article-title">
                  <h6>
                    <a href="#">Event</a>
                  </h6>
                  <h2>{event.title}</h2>
                </div>
                <div
                  className="article-content"
                  dangerouslySetInnerHTML={{ __html: event.content }}
                ></div>
              </article>
            </div>
          </div>
          <Footer />
        </>
      )}
    </React.Fragment>
  );
}

export default EventDetails;
