import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import Footer from "../common/footer";
import Admissionslidebaar from "../sidebars/AdmissionSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const After10th = () => {

  const [modalShow, setModalShow] = React.useState(false);
  const [courseList, setCourseList] = useState([]);

  const eligibility = "10th or Equivalent";

  useEffect(() => {
    const fetchCourseList = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/eligibilityCourseList/${eligibility}`
        );
        setCourseList(response.data.data);
      } catch (error) {
        console.error("Error fetching courseList:", error);
      }
    };

    fetchCourseList();
  }, [eligibility]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Topnav />
      <Menubar />
      <div className="Eligibilitiesinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>After 10th Programmes</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>After 10th Programmes</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Admissionslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">After 10th Programmes</h2>
            <p>
              Programs after 10th grade provide an opportunity to gain
              specialized knowledge in a particular field of interest. This
              helps students develop a deeper understanding of subjects they are
              passionate about and lays a strong foundation for higher
              education.
            </p>
            <p>
              Programs after 10th grade often focus on practical skills
              development, which can be beneficial for students. These programs
              can provide hands-on training, allowing students to develop
              technical, vocational, or industry-specific skills that are valued
              by employers.
            </p>
            <p>
              Engaging in educational programs after 10th grade promotes
              personal growth and development. It helps students become more
              independent, confident, and responsible individuals, preparing
              them for the challenges of the professional world.
            </p>
            <ul className="inner">
              {courseList.map((courseTitle) => (
                <li key={courseTitle.id} className="col-lg-5">
                  <Link to={`/course-details/${courseTitle.name}`}>
                    {courseTitle.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default After10th;
