import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Images/logo/isbm-university.webp";

const Menubar = () => {
  useEffect(() => {
    const handleMouseEnter = (event) => {
      event.target.querySelector(".dropdown-menu").classList.add("show");
    };

    const handleMouseLeave = (event) => {
      event.target.querySelector(".dropdown-menu").classList.remove("show");
    };

    const dropdowns = document.querySelectorAll(".dropdown");

    dropdowns.forEach((dropdown) => {
      dropdown.addEventListener("mouseenter", handleMouseEnter);
      dropdown.addEventListener("mouseleave", handleMouseLeave);
    });

    // Cleanup function
    return () => {
      dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener("mouseenter", handleMouseEnter);
        dropdown.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(
          "https://isbmuniversity.edu.in/admin-portal/schoolsApi"
        );
        setSchools(response.data.data);
        // console.log(response);
      } catch (error) {
        console.error("Error fetching schools:", error);
      }
    };

    fetchSchools();
  }, []);

  return (
    <Container>
      <div className="nav-bg">
        <nav className="navbar navbar-expand-lg navbar-light main">
          <div className="container-fluid">
            <Link to="/1" className="navbar-brand">
              <img
                src={logo}
                className="logo"
                alt="ISBM University"
                style={{ height: "60px" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto me-0">
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    About ISBMU
                  </a>
                  <div className="dropdown-menu megamenu" id="about">
                    <ul className="menuLinks">
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/about-us">About ISBM University</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/vision-mission">Vision and Mission</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/honorable-visitor">Hon'ble Visitor</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/honorable-patron">Hon'ble Patron</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/honorable-chancellor">
                            Hon'ble Chancellor
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/directorgeneral"> Director General</Link>
                        </div>
                      </li>

                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/honorable-vice-chancellor">
                            Hon'ble Vice-Chancellor
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/official-registar"> Registrar</Link>
                        </div>
                      </li>

                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/governing-body"> Governing Body</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/board-management">
                            Board of Management
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/academic-council">Academic Council</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/board-advisor">Board of Advisors</Link>
                        </div>
                      </li>

                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/other-communitie">Other Committees</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/message">Messages of the Dignitaries</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/statues-and-ordinance">
                            Statutes and Ordinances
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/recognisation">
                            Recognitions and Approvals
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/awards">Awards</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/media-coverage">Media Coverage</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/RTI">RTI</Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="https://rajbhavancg.gov.in/" target="_blank">
                            Raj Bhavan
                          </a>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/code-of-conduct-faculty">
                            Code of Conduct (Faculty)
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/code-of-conduct-management">
                            Code of Conduct (Management)
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/code-of-conduct-student">
                            Code of Conduct (Student)
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-3 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/code-of-conduct-boardes-of-hostel">
                            Code of Conduct (Boardes of Hostel)
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Academics
                  </a>
                  <div className="dropdown-menu megamenu" id="academics">
                    <ul className="menuLinks">
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/academic-philosophy">
                            Academic Philosophy
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/academic-partner">Academic Partners</Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/special-development-cells">
                            Special Development Cells
                          </Link>
                        </div>
                      </li> */}
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/online-resources">Online Resources</Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/teaching-methodology-pedagogy">
                            Teaching Methodology/Pedagogy
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/mooc-with-isbm">
                            MOOC with ISBM University
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/mous-and-collaborations">
                            MOUs' and Collaborations
                          </Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/certificate-programmes-and-mdps-certificate-courses">
                            Certificate Programmes and MDPs
                          </Link>
                        </div>
                      </li> */}
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="http://isbmuniversity.online/">
                            Student ERP
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Programmes Offered
                  </a>
                  <div className="dropdown-menu megamenu" id="programmes">
                    <ul className="menuLinks">
                      {schools.map((school) => (
                        <li
                          className="col-lg-3 col-md-4 col-sm-12"
                          key={school.id}
                        >
                          <div className="menuLinks-borderBottom me-4">
                            <Link to={`/school-details/${school.name}`}>
                              {school.title}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Admissions
                  </a>
                  <div className="dropdown-menu megamenu" id="admissions">
                    <ul className="menuLinks">
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/programmes-and-eligibilities">
                            Programmes and Eligibilities
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/programmes-by-qualification">
                            Programmes By Qualification
                          </Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/industry-integrated-programmes">
                            Industry Integrated Programmes
                          </Link>
                        </div>
                      </li> */}
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/skill-development">Skill Development</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/Research">Research</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/apply-online">Apply Online</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/pay-fees-online">Online Payment</Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/downloads">Download and Resources</Link>
                        </div>
                      </li> */}
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/payment-procedure-guidelines">
                            Payment Procedure Guidelines
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/admission-procedure-guidelines">
                            Admission Procedure Guidelines
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/admission-help-desk">
                            Admission Helpdesk
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Student Services
                  </a>
                  <div className="dropdown-menu megamenu" id="studentServices">
                    <ul className="menuLinks">
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/faqs">FAQ</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/scholarship">Scholarship</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/anti-ragging-policy">Anti-Ragging Policy</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/download-form">Download</a>
                        </div>
                      </li>
                      {/* <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/student-grievance">Student Grievance</a>
                        </div>
                      </li> */}
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="https://isbmalumni.com/" target="/">
                            Alumni Portal
                          </a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="https://voterportal.eci.gov.in/" target="/">
                            Voter Portal
                          </a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/anti-ragging-committee">Anti Ragging Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/anti-ragging-squad">Anti-Ragging Squad</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/sc-and-st-committee">Scheduled Caste and Scheduled Tribe Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/obc-committee">Other Backward Class Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/student-grievance-committee">Students' Grievence Redressal Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/icc-committee">Internal Complaints Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/cultural-committee">Cultural Committee</a>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <a href="/student-council">Student Council</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Placements
                  </a>
                  <div className="dropdown-menu megamenu" id="placement">
                    <ul className="menuLinks">
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/about-placement">About Placements</Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/career-counselling">
                            Career Counselling
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/global-skills">Global Skills</Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/recruiters">Recruiters</Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/campus-recruitment-interview-training">
                            Campus Recruitment & Interview Training
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/work-at-isbmu">
                            Work at ISBM University
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static me-3">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    Campus
                  </a>
                  <div className="dropdown-menu megamenu" id="campus">
                    <ul className="menuLinks">
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/overview">Overview</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/library">Library</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/laboratory">Laboratory</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/campus-infrastructure">
                            Campus Infrastructure
                          </Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/information-technology-lab">
                            Information Technology Lab
                          </Link>
                        </div>
                      </li> */}
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/extra-curricular">Extra Curricular</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/digitized-learning-system">
                            Digitized Learning System
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/bus-routes-and-schedules">
                            Bus Routes And Schedules
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/bus-transportation-fees">
                            Bus Transportation Fees
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/hostel">Hostel</Link>
                        </div>
                      </li>
                      {/* <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/complaints">Complaints</Link>
                        </div>
                      </li>
                      <li className="col-lg-6 col-md-12 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/complaint-against-caste-based-discrimination">
                            Complaint Against Caste-Based Discrimination
                          </Link>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li className="nav-item dropdown position-static">
                  <a
                    className="nav-link py-4 dropdown-toggle"
                    href="#"
                    id="navbarDropdown1"
                    role="button"
                  >
                    International
                  </a>
                  <div className="dropdown-menu megamenu" id="international">
                    <ul className="menuLinks">
                      <li className="col-lg-12 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/international-partnerships">
                            International Partnerships
                          </Link>
                        </div>
                      </li>
                      <li className="col-lg-12 col-md-4 col-sm-12">
                        <div className="menuLinks-borderBottom me-4">
                          <Link to="/international-students">
                            International Students
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </Container>
  );
};

export default Menubar;
